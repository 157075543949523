<template>
  <div class="width-all" id="supplierEditGoodsPages" @scroll.navite="mainScrollEvent">
    <div class="flex justify-content-center">
      <!--左侧的预览视图-->
      <div class="goods-mobile-model-warp">
        <div class="goods-mobile-model absolute" :style="'top:' + scrollTop + 'px'">
          <div class="goods-preview">
            <div class="scroll-view">
              <div class="scroll-view-box">
                <el-carousel height="375px" indicator-position="none" :autoplay="false" arrow="always"
                  v-if="slideList.length || data.baseGoodsInfo.video">
                  <el-carousel-item v-if="getBannerVideoVal">
                    <video :src="getBannerVideoVal.video" controls="controls" style="width:375px;height:375px;"></video>
                  </el-carousel-item>
                  <el-carousel-item v-for="(url, index) in slideList" :key="index + '_' + url">
                    <el-image style="width: 375px; height: 375px" class="box-img" :src="url" fit="cover"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <el-image style="width: 375px; height: 375px" v-else></el-image>
              </div>
              <div class="scroll-view-body">
                <div class="goods-base body-item">
                  <div class="product-price">{{ previewSkuPrice }}</div>
                  <div class="product-goods-name padding-top-10 text-over-hidden">
                    {{ data.baseGoodsInfo.name }}
                  </div>
                  <div class="product-goods-brief padding-top-10 text-over-hidden">
                    {{ data.baseGoodsInfo.brief }}
                  </div>
                </div>
                <div class="padding-10 body-item">
                  <div class="flex">
                    <div class="gray-text">选择</div>
                    <div class="flex-1 margin-left-10" style="color: #353535">
                      {{ previewSkuTitle }}
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="padding-left-10 gray-text">商品详情</div>
                  <div class="detail-text">
                    {{ data.baseGoodsInfo.detailsText }}
                  </div>
                  <div class="detail-images" v-for="(url, index) in detailsImages" :key="index + '_' + url">
                    <el-image :src="url"></el-image>
                  </div>
                </div>
                <div class="" v-if="data.baseGoodsInfo.refundExplainText ||
                  refundExplainImages.length
                  ">
                  <div class="padding-left-10 gray-text">售后须知</div>
                  <div class="detail-text">
                    {{ data.baseGoodsInfo.refundExplainText }}
                  </div>
                  <div class="detail-images" v-for="url in refundExplainImages" :key="url">
                    <el-image :src="url"></el-image>
                  </div>
                </div>
              </div>
            </div>
            <div class="sku-dialog" v-if="showSkuMask">
              <div class="sku-mask"></div>
              <div class="sku-body">
                <div class="close-button" @click="showSkuMask = false"></div>
                <div class="sku-body-content">
                  <div class="sku-body-content-item">
                    <div class="sku-preview flex">
                      <el-image class="sku-preview-img" :src="previewSkuImage"></el-image>
                      <div class="flex-1">
                        <div class="sku-preview-price">
                          {{ previewSkuPrice }}
                        </div>
                        <div class="sku-preview-stock">
                          {{ previewSkuStockNum }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sku-body-content-item">
                    <div class="sku-list" v-if="data.skuItemList.length">
                      <div class="sku-item" v-for="(skuItem, ki) in data.skuItemList" :key="ki">
                        <div class="item-title gray-text">
                          {{ skuItem.skuItemName }}
                        </div>
                        <div class="item-tags flex" v-if="skuItem.skuItemValList">
                          <!--<div class="item-tag active">白色</div>-->
                          <!--<div class="item-tag">黑色</div>-->
                          <div class="item-tag" :class="{
                            active:
                              currentSelectSku[ki] &&
                              currentSelectSku[ki].skuItemValName ===
                              skuValue.skuItemValName
                          }" v-for="(skuValue, vi) in skuItem.skuItemValList" :key="vi"
                            @click="previewSkuTagClick(ki, skuValue)">
                            {{ skuValue.skuItemValName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sku-count flex">
                      <div class="flex-1 flex align-items-center">
                        <span class="gray-text">数量</span>
                      </div>
                      <div class="sku-count-ctrl">
                        <div class="count-ctrl-btn">
                          <span>－</span>
                        </div>
                        <div class="count-ctrl-input"><span>1</span></div>
                        <div class="count-ctrl-btn">
                          <span>＋</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer flex">
              <div class="flex-2 flex">
                <div class="
                    flex-1 flex flex-column
                    align-items-center
                    justify-content-center
                  ">
                  <i class="el-icon-house"></i>
                  <div class="label-text">店铺</div>
                </div>
                <div class="
                    flex-1 flex flex-column
                    align-items-center
                    justify-content-center
                  ">
                  <i class="el-icon-headset"></i>
                  <div class="label-text">客服</div>
                </div>
                <div class="
                    flex-1 flex flex-column
                    align-items-center
                    justify-content-center
                  ">
                  <i class="el-icon-shopping-cart-1"></i>
                  <div class="label-text">购物车</div>
                </div>
              </div>
              <div class="flex-3 flex">
                <div class="flex-1 flex align-items-center justify-content-center">
                  <div class="add-cart" @click="showSkuMask = true">
                    加入购物车
                  </div>
                </div>
                <div class="flex-1 flex align-items-center justify-content-center">
                  <div class="buy-now" @click="showSkuMask = true">
                    立即购买
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--右侧的编辑表单-->
      <el-form class="edit-goods-wrap" :model="data" :ref="formNameEditGoods" v-loading="loading" @submit.native.prevent>
        <!-- 商品信息 -->
        <div class="edit-goods-module">
          <div class="edit-goods-header">
            <div class="left title">商品信息</div>
            <div class="content category-text" v-if="data.baseGoodsInfo.brandId">
              {{ data.baseGoodsInfo.cateDetails }} >
              {{ data.baseGoodsInfo.brandName }}
            </div>
            <div class="content category-text" v-else>请选择基础分类</div>
            <el-link class="right" type="primary" @click="showSelectCategoryAndBrand">修改分类
              <el-icon class="el-icon-arrow-right"></el-icon>
            </el-link>
          </div>
          <div class="flex-item margin-tb" v-if="!canOpenEdit">
            <el-button class="content" type="danger" @click="showSelectCategoryAndBrand">请先选择基础分类
              <el-icon class="el-icon-arrow-right"></el-icon>
            </el-button>
          </div>
          <template v-else>
            <div class="flex-item" v-if="isSupportWholesale == 2">
              <el-form-item class="form-item form-item-label-row content" label="商品类型" :rules="formRules.required"
                ref="baseGoodsInfo.type" prop="baseGoodsInfo.businessType">
                <el-select v-model="data.baseGoodsInfo.businessType" class="width-all" placeholder="请选择"
                  :disabled="isEdit" @change="selectBusinessType">
                  <el-option v-for="item in salesModelList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="data.baseGoodsInfo.businessType == 1" class="form-item form-item-label-row content"
                label="所属村服务点店铺" ref="baseGoodsInfo.supplierId" prop="baseGoodsInfo.supplierId">
                <el-select v-model="data.baseGoodsInfo.supplierId" class="width-all" placeholder="请选择" :disabled="isEdit"
                  @change="selectSupplier">
                  <el-option v-for="item in supplierList" :key="item.supplierId" :label="item.suplierName"
                    :value="item.supplierId">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <div
                class="content"
                style="margin-top: 16px"
                v-show="data.baseGoodsInfo.businessType == 2 && !isEdit"
              >
                <el-button type="primary" @click="commodityDataImport"
                  >选择商品导入</el-button
                >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="在批发销售模式下，可将现存的某一商品数据导入到该界面。"
                  placement="right"
                >
                  <i class="el-icon-warning" style="margin-left: 20px"></i>
                </el-tooltip>
              </div> -->
            </div>
            <div class="flex-item">
              <el-form-item class="form-item form-item-label-row content" label="商品名称" :rules="formRules.goodsName"
                ref="baseGoodsInfo.name" prop="baseGoodsInfo.name">
                <el-input v-model="data.baseGoodsInfo.name"></el-input>
              </el-form-item>
              <el-form-item class="form-item form-item-label-row content" label="商品简介" :rules="formRules.required"
                ref="baseGoodsInfo.brief" prop="baseGoodsInfo.brief">
                <el-input v-model="data.baseGoodsInfo.brief"></el-input>
              </el-form-item>
            </div>

            <el-form-item class="form-item form-item-label-row content" label="" :rules="formRules.required"
              ref="baseGoodsInfo.banners" prop="baseGoodsInfo.banners">
              <el-input v-model="data.baseGoodsInfo.banners" v-show="false"></el-input>
              <template slot="label">商品图片（{{ slideList.length }}/9）</template>
              <upload-images :file-list.sync="slideList" width="100px" isCropper :canvas="editGoods"
                :limit="9"></upload-images>
            </el-form-item>

            <el-alert title="最多上传9个图片（第一张默认为封面图），单张图片需限制在10M以内。 " type="info">
            </el-alert>
            <el-form-item class="form-item form-item-label-row content" label="" :rules="formRules.required">
              <el-input v-model="data.baseGoodsInfo.posterList" v-show="false"></el-input>
              <template slot="label">海报封面</template>
              <!-- <upload-images :file-list.sync="posterList" width="100px" isCropper :canvas="editGoods"
                  :limit="1" :aspectRatio="999" ></upload-images> -->
              <upload-images-one :file.sync="posterList" width="100px" height="100px" :files-size="10"
                files-size-unit="Mb"></upload-images-one>
            </el-form-item>

            <el-alert title="最多上传一张海报图，尺寸为702*320，前端将展示横屏的该报图大小不超过10M以内" type="info">
            </el-alert>
            <el-form-item label="视频" class="form-item form-item-label-row content" prop="baseGoodsInfo.video">
              <UploadVodVideo :filesSize="10240" :file="data.baseGoodsInfo.video ? data.baseGoodsInfo.video.video : ''
                " @update:file="changeVodVideoFiles" />
            </el-form-item>
            <el-alert title="高清视频有利于推荐，建议分辨率不低于 1920*1080（大小不超过 20M）" type="info">
            </el-alert>

            <el-form-item :label="'产业监控(' + numbers + '/1)'" class="form-item form-item-label-row content"
              prop="baseGoodsInfo.video">
              <div class="monitor-title"></div>
              <div class="monitor" @mouseover="shadow = true" @mouseleave="shadow = false">
                <i v-if="!monitorImg" @click="dialogTableVisible = true" class="el-icon-plus"></i>
                <div class="monitor-img" v-if="monitorImg">
                  <img :src="monitorImg" />
                </div>
                <div class="shadow-box" v-if="shadow && monitorImg">
                  <i class="el-icon-edit-outline" @click="dialogTableVisible = true"></i>
                  <i class="el-icon-delete" @click="deleteMonitor" style="margin-left: 20px;"></i>
                </div>
              </div>
            </el-form-item>
            <!-- <el-alert
                title=""
                 type="info"
              >
            </el-alert> -->

            <el-form-item v-if="getBannerVideoVal" :rules="formRules.required" prop="baseGoodsInfo.videoCover" label=""
              style="margin-top:20px">
              <span style="font-size:16px;color:#909399"><span style="color:#f56c6c;margin-right:4px">*</span>封面</span>
              <div @click="isVideoSnapshot = true"
                style="width: 190px;height: 108px;line-height:108px;text-align:center;border: 1px dashed #e9e9e9;cursor: pointer;margin-bottom:20px">
                <img v-if="data.baseGoodsInfo.videoCover" :src="data.baseGoodsInfo.videoCover"
                  style="width: 190px;height: 108px;border: 1px solid #e9e9e9;cursor: pointer;" alt="" />
                <div class="" v-else>
                  <i class="el-icon-plus avatar-uploader-icon" style="font-size:28px;"></i>
                </div>
              </div>
              <el-alert title="清晰美观的封面有利于推荐，建议分辨率不低于 1920*1080（大小不超过 20M）" type="info">
              </el-alert>
            </el-form-item>
          </template>
        </div>
        <!-- 商品详情 -->
        <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <span class="title">商品详情</span>
            <span class="content color-info">（选填）</span>
          </div>
          <el-form-item class="form-item form-item-label-row content" prop="baseGoodsInfo.detailsText">
            <el-input type="textarea" :rows="8" maxlength="512" show-word-limit placeholder="商品详细介绍，限512字符..."
              v-model="data.baseGoodsInfo.detailsText">
            </el-input>
          </el-form-item>

          <el-form-item class="form-item form-item-label-row content" label="" prop="baseGoodsInfo.detailsImages">
            <upload-images :file-list.sync="detailsImages" width="100px" :limit="20"></upload-images>
          </el-form-item>
        </div>
        <!-- 批发配置 -->
        <div class="edit-goods-module" v-if="canOpenEdit && data.baseGoodsInfo.businessType == 102">
          <div class="edit-goods-header">
            <div class="title">批发配置</div>
            <div class="content"></div>
          </div>
          <div class="flex-item">
            <el-form-item class="form-item form-item-label-row content" label="批发类型" :rules="formRules.required"
              ref="baseGoodsInfo.wholeSaleType" prop="baseGoodsInfo.wholeSaleType">
              <el-select v-model="data.baseGoodsInfo.wholeSaleType" class="width-all" :disabled="isEdit"
                placeholder="请选择">
                <el-option v-for="item in wholeSaleTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="content"></div>
          </div>
          <editWholeSaleRule ref="wholesaleRules" :baseGoodsInfo.sync="data.baseGoodsInfo"
            v-if="data.baseGoodsInfo.wholeSaleType == 2"></editWholeSaleRule>
          <el-form-item v-else :rules="formRules.required" ref="baseGoodsInfo.wholeSaleRuleList[0].beginNum"
            prop="baseGoodsInfo.wholeSaleRuleList[0].beginNum">
            <el-input style="width: 150px" type="number" placeholder="起批数"
              v-model="data.baseGoodsInfo.wholeSaleRuleList[0].beginNum">
              <span slot="suffix" style="line-height: 40px; margin-right: 10px">件</span>
            </el-input>
            <el-tooltip class="item" effect="dark" content="购买数量需达到您设置的起批数才可购买" placement="right">
              <i class="el-icon-warning" style="margin-left: 20px"></i>
            </el-tooltip>
          </el-form-item>
        </div>
        <!-- 商品sku -->
        <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <div class="title">商品SKU</div>
            <div class="content"></div>
          </div>
          <edit-sku ref="goodsSkuListRef" :default-sku-items.sync="data.skuItemList" :type.sync="data.baseGoodsInfo.type"
            :default-goods-sku-list.sync="data.goodsSkuList" :showCofig="data.baseGoodsInfo.wholeSaleType"
            :basic-sales-switch.sync="data.baseGoodsInfo.basicSalesSwitch"></edit-sku>
        </div>
        <!-- 预告区域 -->
        <!-- <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <div class="title">预告区域</div>
          </div>
          <div class="presell-main">
            <el-form-item label="是否是预告商品">
              <el-radio-group v-model="data.baseGoodsInfo.isPresell"
                @change="changePresellRadio('baseGoodsInfo.presellTimeList')">
                <el-radio :label="2">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="请填写预告时间" ref="baseGoodsInfo.presellTimeList" prop="baseGoodsInfo.presellTimeList"
              :rules="
                data.baseGoodsInfo.isPresell != 1 ? null : formRules.required
              ">
              <el-date-picker type="dates" :disabled="data.baseGoodsInfo.isPresell != 1" placeholder="请选择时间"
                v-model="data.baseGoodsInfo.presellTimeList" value-format="yyyy-MM-dd" format="MM-dd"
                :picker-options="presellPickerOptions" style="width: 40%"></el-date-picker>
            </el-form-item>
            <div class="presell-tip flex">
              <b>温馨提示:</b>
              <p class="flex-1">
                当选择为预告商品时，在APP云集市页面中将会显示预告的商品，预告时间为统一您选择日的下午17:30-22:30，且选择日的第二天将会变为赶集商品，赶集时间统一为早7:30-10:30
              </p>
            </div>
          </div>
        </div> -->
        <!-- 物流信息 -->
        <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <div class="title">物流信息</div>
            <div class="content"></div>
            <!-- <el-link class="right" type="primary" @click="showSelectPostage">修改运费模板
              <el-icon class="el-icon-arrow-right"></el-icon>
            </el-link> -->
            <el-button icon="el-icon-refresh-left" type="primary" plain size="mini" style="margin-right: 50px"
              @click="refreshBtn">刷新运费模板数据</el-button>
            <el-link class="right" type="primary" @click="goFreightTemplate">前往运费模板
              <el-icon class="el-icon-arrow-right"></el-icon>
            </el-link>
          </div>
          <div>
            <el-row class="edit-mail-data-wrap">
              <el-col :span="3">配送方式1</el-col>
              <el-col :span="21">
                <el-checkbox v-model="data.baseGoodsInfo.isSupportPostage" :true-label="1" :false-label="2"
                  :disabled="mailValue || !selectFreightTemplateData">邮寄快递</el-checkbox>
                <el-row v-if="mailTemplateList.length > 0" :span="20" class="edit-mail-data" type="flex">
                  <el-col :span="3">运费模板:</el-col>
                  <el-col :span="21">
                    <el-select v-model="data.baseGoodsInfo.freightTemplateId" placeholder="请选择"
                      @change="selectMailTemplate">
                      <el-option v-for="item in mailTemplateList" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row v-if="selectFreightTemplateData" :span="20" class="edit-mail-data" type="flex">
                  <el-col :span="3">
                    <span>模板信息:</span>
                  </el-col>
                  <el-col :span="21" style="
                      background: #66b1ff29;
                      padding: 0 10px;
                      border-radius: 4px;
                    ">
                    <div style="padding: 5px 0">
                      发货地：{{
                        selectFreightTemplateData
                        ? JSON.parse(selectFreightTemplateData.addrJson)
                          .receiveAddressInfo
                        : ""
                      }}
                    </div>
                    <div style="padding: 5px 0">
                      运费：{{
                        selectFreightTemplateData
                        ? selectFreightTemplateData.defaultFeeText
                        : ""
                      }}
                    </div>
                    <div style="padding: 5px 0; max-height: 100px; overflow: auto">
                      暂不发货地：{{
                        selectFreightTemplateData
                        ? getNonDeliveryArea(
                          selectFreightTemplateData.nonDeliveryArea
                        )
                        : ""
                      }}
                    </div>
                  </el-col>
                </el-row>
                <el-row v-if="selectFreightTemplateData" :span="20" class="edit-mail-data" type="flex">
                  <el-col :span="3">
                    <span v-if="selectFreightTemplateData.billingType == 2">物流重量:</span>
                    <span v-if="selectFreightTemplateData.billingType == 3">物流体积:</span>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item v-if="selectFreightTemplateData.billingType == 2" prop="baseGoodsInfo.calculateWeight"
                      :rules="formRules.postageNumber" style="margin: 0px">
                      <el-input placeholder="请输入重量" v-model="data.baseGoodsInfo.calculateWeight">
                        <template slot="append">千克</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="selectFreightTemplateData.billingType == 3" prop="baseGoodsInfo.calculateVolume"
                      :rules="formRules.postageNumber" style="margin: 0px">
                      <el-input placeholder="请输入体积" v-model="data.baseGoodsInfo.calculateVolume">
                        <template slot="append">立方米</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <el-tooltip class="item" effect="dark" content="当前运费模板，按物流重量（含包装）计费" placement="top-start"
                      v-if="selectFreightTemplateData.billingType == 2">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="当前运费模板，按物流体积（含包装）计费" placement="top-start"
                      v-if="selectFreightTemplateData.billingType == 3">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row v-if="!selectFreightTemplateData">
                  <el-col style="font-size: 14px; margin-top: 20px">未找到设为默认的邮寄快递模板，请前往设置！</el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- <el-row class="edit-mail-data-wrap">
              <el-col :span="3">配送方式2</el-col>
              <el-col :span="21">
                <el-checkbox
                  v-model="data.baseGoodsInfo.isSupportSameCity"
                  :true-label="1"
                  :false-label="2"
                  :disabled="cityValue || !sameCityContent.address"
                  >县域配送</el-checkbox
                >
                <el-row :span="20" class="edit-mail-data" type="flex">
                  <el-col :span="3">
                    <span>模板信息:</span>
                  </el-col>
                  <el-col
                    :span="21"
                    v-if="sameCityContent.supplierSameCityDelivery"
                    style="
                      background: #66b1ff29;
                      padding: 0 10px;
                      border-radius: 4px;
                    "
                  >
                    <div style="padding: 5px 0">配送方：商家自送</div>
                    <div
                      style="padding: 5px 0"
                      v-if="
                        sameCityContent.supplierSameCityDelivery
                          .deliveryDistanceType == 1
                      "
                    >
                      配送范围：{{
                        sameCityContent.supplierSameCityDelivery
                          .supportDistance + "公里以内"
                      }}
                    </div>
                    <div style="padding: 5px 0" v-else>
                      <!-- 配送范围：{{
                      sameCityContent.isOpenSameCity == 2 ? '县域配送' : (sameCityContent.supplierSameCityDelivery
                        ? sameCityContent.address.province +
                          sameCityContent.address.city
                        : sameCityContent.address.province +
                          sameCityContent.address.city +
                          sameCityContent.address.area)
                      }} -->
            <!--  {{ getDeliveryArea }}
                    </div>
                    <div style="padding: 5px 0">
                      计价方式：{{ calculateWay }}
                    </div>
                    <div style="padding: 5px 0" v-show="false">
                      特殊时段：{{ specialHoursText }}
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row> -->
            <!-- <el-row class="edit-mail-data-wrap">
              <el-col :span="3">配送方式3</el-col>
              <el-col :span="21">
                <el-checkbox
                  v-model="data.baseGoodsInfo.isSupportSelfMention"
                  :true-label="1"
                  :false-label="2"
                  :disabled="pickUpValue || !selfMentionContent.address"
                  >到店自提</el-checkbox
                >
                <el-row :span="20" class="edit-mail-data" type="flex">
                  <el-col :span="3">
                    <span>模板信息:</span>
                  </el-col>
                  <el-col
                    :span="21"
                    style="
                      background: #66b1ff29;
                      padding: 0 10px;
                      border-radius: 4px;
                    "
                  >
                    <div style="padding: 5px 0">
                      自提地址：
                      {{
                        selfMentionContent.address
                          ? selfMentionContent.address.addressInfo +
                            selfMentionContent.address.address
                          : "未设置自提地址信息"
                      }}
                    </div>
                    <div
                      style="padding: 5px 0"
                      v-if="
                        selfMentionContent.supplierSelfMention &&
                        selfMentionContent.supplierSelfMention.timeType == 2
                      "
                    >
                      自提时间：
                      {{
                        selfMentionContent.supplierSelfMention.startTime +
                        "-" +
                        selfMentionContent.supplierSelfMention.endTime
                      }}
                    </div>
                    <div style="padding: 5px 0" v-else>自提时间：24小时</div>
                    <div style="padding: 5px 0" v-if="false">
                      备货时间：
                      {{
                        selfMentionContent.supplierSelfMention.stockingTime +
                        "分钟内"
                      }}
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row> -->
          </div>
          <!-- <el-form-item class="form-item form-item-label-row "
                        label=""
                        :rules="formRules.required"
                        ref="baseGoodsInfo.freightTemplateId"
                        prop="baseGoodsInfo.freightTemplateId">
            <template v-if="data.baseGoodsInfo.freightTemplateId">
              <div class="postage-item"><span class="postage-label">模板名称：</span>{{data.baseGoodsInfo.freightTemplateName}}
              </div>
              <div class="postage-item"><span class="postage-label">模板 I D ：</span>{{data.baseGoodsInfo.freightTemplateId}}
              </div>
            </template>
            <el-button class="width-all"
                       type="danger"
                       icon="el-icon-plus"
                       @click="showSelectPostage"
                       v-else>选择运费模板
            </el-button>
          </el-form-item>

          <div class="flex-item" v-if="data.baseGoodsInfo.freightTemplateId">
            <el-form-item class="form-item  form-item-label-row content"
                          label="体积"
                          :rules="formRules.volumnRule"
                          prop="baseGoodsInfo.calculateVolume"
                          v-if="data.baseGoodsInfo.freightTemplateType === 3">
              <el-input v-model="data.baseGoodsInfo.calculateVolume" type="text">
                <template slot="append">m³</template>
              </el-input>
            </el-form-item>
            <el-form-item class="form-item  form-item-label-row content"
                          label="重量"
                          :rules="formRules.volumnRule"
                          prop="baseGoodsInfo.calculateWeight"
                          v-if="data.baseGoodsInfo.freightTemplateType === 2">
              <el-input v-model="data.baseGoodsInfo.calculateWeight" type="text">
                <template slot="append">kg</template>
              </el-input>
            </el-form-item>
            <div class="content"></div>
          </div> -->
        </div>
        <!-- 搜索关键词 -->
        <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <span class="title">搜索关键词</span>
            <span class="content color-info">（选填）</span>
          </div>
          <div class="flex-item">
            <el-form-item class="form-item form-item-label-row content" label="搜索关键词" prop="baseGoodsInfo.keywords">
              <el-input v-model="data.baseGoodsInfo.keywords"></el-input>
            </el-form-item>
            <div class="content"></div>
          </div>
          <el-form-item v-if="!loading" class="form-item form-item-label-row content" label="类目属性"
            prop="baseGoodsInfo.keywords">
            <select-attribute-list :id="data.baseGoodsInfo.thirdCateId"
              :default-list.sync="data.goodsAttributeList"></select-attribute-list>
          </el-form-item>
        </div>
        <!-- 售后相关 -->
        <div class="edit-goods-module" v-if="canOpenEdit">
          <div class="edit-goods-header">
            <div class="title">售后相关</div>
            <span class="content color-info">（选填）</span>
          </div>

          <el-form-item class="form-item form-item-label-row" label="支持退换货" prop="baseGoodsInfo.supportRefund">
            <el-select v-model="data.baseGoodsInfo.supportRefund" placeholder="请选择">
              <el-option v-for="item in supportRefundArray" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="form-item form-item-label-row content" label="售后须知" prop="baseGoodsInfo.refundExplainText">
            <el-input type="textarea" :rows="8" maxlength="512" show-word-limit placeholder="售后须知文本，限512字符..."
              v-model="data.baseGoodsInfo.refundExplainText">
            </el-input>
          </el-form-item>

          <el-form-item class="form-item form-item-label-row content" label="" prop="baseGoodsInfo.refundExplainImages">
            <upload-images :file-list.sync="refundExplainImages" width="100px" :limit="3"
              :show-hint-text="true"></upload-images>
          </el-form-item>
        </div>
        <!-- 提交、保存 -->
        <div class="edit-goods-module edit-goods-action-wrap" v-if="canOpenEdit">
          <div class="flex-item">
            <div class="content"></div>
            <el-button class="right" type="" @click="submitFormDraft(formNameEditGoods)">
              <el-icon class="el-icon-edit-outline margin-right-10" />
              保存草稿
            </el-button>
            <el-button class="right" type="primary" @click="submitForm(formNameEditGoods)">
              <el-icon class="el-icon-edit-outline margin-right-10" />
              提交审核
            </el-button>
          </div>

          <div class="fixed-action-right-bottom edit-goods-action">
            <el-button type="" @click="goBackTop" v-if="showBackTop">
              <el-icon class="el-icon-top" />
              返回顶部
            </el-button>
            <el-button type="" @click="goBack">
              <el-icon class="el-icon-back" />
              返回上页
            </el-button>
            <el-button type="" @click="submitFormDraft(formNameEditGoods)">
              <el-icon class="el-icon-edit-outline" />
              保存草稿
            </el-button>
            <el-button type="primary" @click="submitForm(formNameEditGoods)">
              <el-icon class="el-icon-edit-outline" />
              提交审核
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <select-store-goods :visible.sync="showGoodsDialog" @saveObj="saveObj" :businessType="1"></select-store-goods>
    <select-category-and-brand :visible.sync="showDialogSelectCategory" :default-category-list="categoryValue"
      :default-brand-id="data.baseGoodsInfo.brandId"
      @success="changeSelectCategoryAndBrandSuccess"></select-category-and-brand>
    <select-postage :visible.sync="showDialogSelectPostage" v-model="data.baseGoodsInfo.freightTemplateId"
      @change="selectPostageItem"></select-postage>

    <VideoSnapshot v-if="isVideoSnapshot" :datum="data.baseGoodsInfo.video"
      @update:file="img => (data.baseGoodsInfo.videoCover = img)" />

    <el-dialog title="添加关联产业监控" :visible.sync="dialogTableVisible">
      <alert-template @closed="closed" @intoData="intoData"></alert-template>
    </el-dialog>
  </div>
</template>

<script>
import selectCategoryAndBrand from "@/components/supplier/editGoods/selectCategoryAndBrand";
import SelectStoreGoods from "@/components/supplier/select/selectStoreGoods";
import selectPostage from "@/components/supplier/editGoods/selectPostage";
import selectAttributeList from "@/components/supplier/editGoods/selectAttributeList";
import uploadImages from "@/components/public/upload/uploadImages";
import editSku from "@/components/supplier/editGoods/editSku";
import editWholeSaleRule from "@/components/supplier/editGoods/editWholeSaleRule";
import { returnBaseDataGoodsDetails } from "@/assets/js/modules/goods/goodsDetails";
import * as formRules from "@/assets/js/formRules";
import {
  goodsManufactureTypeArray,
  supportRefundArray
} from "@/assets/js/status";
import { scrollToView } from "@/assets/js/utils";
import VideoSnapshot from "@/components/supplier/editPostage/video-snapshot.vue";
import UploadVideo from "@/components/public/upload/uploadVideo.vue";
import UploadImagesOne from "@/components/public/upload/uploadImagesOne.vue";
import AlertTemplate from "@/components/monitor/alertTemplate.vue";
import UploadVodVideo from "@/components/public/upload/uploadVodVideo.vue";
import { editGoods } from "@/config/imageSize";

import {
  deepsClone,
  isObjectPure,
  isStringNumber,
  JSONParse,
  JSONStringify,
  toInteger,
  toNumberPrice
} from "../../../assets/js/utils";
import { URL } from "../../../config/url.supplier";
import router from "../../../router";
import List from "./list.vue";

export default {
  name: "supplierEditGoods",
  components: {
    selectCategoryAndBrand,
    selectAttributeList,
    selectPostage,
    uploadImages,
    editSku,
    SelectStoreGoods,
    editWholeSaleRule,
    VideoSnapshot,
    UploadVideo,
    UploadVodVideo,
    UploadImagesOne,
    AlertTemplate
  },
  data() {
    return {
      allowedLeave: false,
      shadow: false,
      numbers: 0,
      monitorImg: null,
      isInit: false,
      loading: false,
      isVideoSnapshot: false,
      dialogTableVisible: false,
      intoDataList: null,
      formRules,
      formNameEditGoods: "formNameEditGoods",
      salesModelList: [
        //   {
        //   id: 1,
        //   name: '普通销售'
        // },
        {
          id: 2,
          name: "包装"
        },
        {
          id: 3,
          name: "运营商品"
        },
        {
          id: 4,
          name: "产品拍图"
        }
      ],
      wholeSaleTypeList: [
        {
          id: 2,
          name: "按数量批发"
        },
        {
          id: 3,
          name: "按规格批发"
        }
      ],
      /** 编辑类型、相关参数 */
      refreshData: false, // 是否在刷新数据？ 为true时重置sku等组件
      // 编辑草稿箱
      isDraft: false,
      draftId: "",
      originPageUrL: "",
      // 正式商品
      isOfficial: false,
      /** 基础数据结构 */
      data: returnBaseDataGoodsDetails(),
      /** 选择基础分类、品牌 */
      showDialogSelectCategory: false,
      showGoodsDialog: false,
      categoryValue: [],
      categoryText: [],
      categoryData: [],
      // /** 轮播图*/
      slideList: [],
      posterList: [],
      // /** 商品详情图片*/

      detailsImages: [],
      /** 选择运费模板 */
      showDialogSelectPostage: false,
      /** 商品类型 */
      goodsManufactureTypeArray,
      /** 商品制造类型 */
      supportRefundArray,
      refundExplainImages: [],
      // 容器滚动条
      showBackTop: false,
      scrollTop: 0,
      showSkuMask: false,
      currentSelectSku: [],
      selectedSku: undefined,
      supplierList: [], // 村店铺列表-帮村加商品
      goodsAttributeList: [], // 类目属性
      beginNum: "", // 起批数-多少件
      isEdit: false, // 是否是编辑
      isChannel: false, // 是否是导入的数据
      freightTemplateId: "", // 选中的邮寄运费模板id
      mailList: [], // //下拉邮寄运费模板列表(详细数据)
      mailTemplateList: [], // 下拉邮寄运费模板列表
      selectFreightTemplateData: null, // 选中的选中的邮寄运费模板详情
      sameCityContent: {}, // 县域配送数据
      selfMentionContent: {}, // 自提配送数据
      mailValue: false, // 是否启用邮寄
      cityValue: false, // 是否启用县域
      pickUpValue: false, // 是否启用自提
      isSupportWholesale: JSON.parse(localStorage.getItem("user_info"))
        .siteType, // 只有供应商才能上传批发商品
      coverVideoDetails: "" /** 封面视频详情 */,
      isPlay: false,
      controls: false,
      swiperPlay: false, // 轮播中暂停
      editGoods
    };
  },
  created() {
    this.pageInit();
    if (this.isSupportWholesale == 2) {
      this.getVillageStores();
    }
    this.routingProcess();
  },
  mounted() {
    setTimeout(() => {
      this.getList();
    }, 2000);
    this.getSameCityContent();
    this.getSelfMentionContent();
    this.getSupplierDelivery();
  },
  destroyed() {
    // this.removeEventScroll();
  },
  methods: {
    deleteMonitor() {
      this.numbers = 0;
      this.monitorImg = "";
      this.intoDataList.isMonitor = 0;
    },
    closed(val) {
      this.dialogTableVisible = val;
    },
    intoData(obj) {
      this.intoDataList = obj;
      this.monitorImg = obj.monitorImg;
      this.numbers = "1";
    },
    suspend() {
      var vide = document.getElementById("vide");
      if (vide.paused) {
        vide.play();
        this.isPlay = true;
        this.controls = true;
      }
    },
    onPlayer(event) {
      this.isPlay = true;
      this.controls = true;
    },
    onPlayerPause(event, m) {
      if (!this.swiperPlay) {
        this.isPlay = false;
        this.controls = false;
      }
    },
    changeIndex(index) {
      if (this.videoSrc) {
        var vide = document.getElementById("vide");
        if (index == 0 && this.isPlay) {
          this.swiperPlay = false;
          vide.play();
        } else if (index != 0 && this.isPlay) {
          this.swiperPlay = true;
          vide.pause();
        }
      }
    },
    routingProcess() {
      if (this.$route.params.data) {
        const { data } = this.$route.params;
        this.form = {
          title: data.title,
          cover: data.cover,
          videoDetail: {
            video: data.video,
            videoHeight: data.videoHeight,
            videoWidth: data.videoWidth
          }
        };
        this.videoTypeValue = [data.firstCategoryId, data.lastCategoryId];
        this.extendObj = {
          extensionColumn: data.videoExtensionRspModelList
        };
        this.$refs.UploadVideo.setVideoUrl(data.video);
      }
    },

    // 页面初始话
    pageInit() {
      let params = this.$route.query;
      if (params.draftId) {
        this.isEdit = true;
      }
      this.originPageUrL = params.originPageUrL || "";
      // 新建进
      let refreshDraft = () => {
        if (!this.isInit) return false;
        this.isDraft = false;
        this.isOfficial = false;
        this.draftId = "";
        this.getEditDataSuccess(returnBaseDataGoodsDetails());
      };
      // 草稿进
      if (params.isDraft && params.draftId) {
        refreshDraft = () => {
          if (this.draftId === params.draftId) return false;
          this.isDraft = true;
          this.isOfficial = false;
          this.draftId = params.draftId;
          this.getDraftGoodsDetails();
        };
      }
      // 编辑进
      if (params.isOfficial && params.draftId) {
        refreshDraft = () => {
          if (this.draftId === params.draftId) return false;
          this.isDraft = false;
          this.isOfficial = true;
          this.draftId = params.draftId;
          this.getGoodsInfo();
        };
      }
      refreshDraft();
      this.isInit = true;
    },
    // 若当前登录的市镇级店铺，还需获取旗下的村店铺
    getVillageStores() {
      this.axios.get(URL.supplier.getVillageStores).then(res => {
        if (res.code == 0) {
          this.supplierList = res.data;
        }
      });
    },
    // 获取邮寄快递的模板列表
    getList() {
      let data = {
        supplierId: this.data.baseGoodsInfo.supplierId
      };
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.supplierPostage.list, { params: data })
          .then(res => {
            let list = [];
            this.mailList = res.data;
            if (res.data.length == 0) {
              this.selectFreightTemplateData = null;
            } else {
              let haveDefault = true;
              res.data.forEach((item, index) => {
                list.push({
                  value: item.id,
                  label: item.name
                });
                if (this.data.baseGoodsInfo.freightTemplateId) {
                  if (item.id == this.data.baseGoodsInfo.freightTemplateId) {
                    this.freightTemplateId = item.id;
                    this.selectMailTemplate(item.id);
                    haveDefault = false;
                  } else {
                    if (haveDefault) {
                      this.selectFreightTemplateData = null;
                    }
                  }
                } else if (item.isDefault == 1) {
                  this.freightTemplateId = item.id;
                  this.selectMailTemplate(item.id);
                  haveDefault = false;
                } else {
                  if (haveDefault) {
                    this.selectFreightTemplateData = null;
                  }
                }
              });
            }
            this.mailTemplateList = list;
            resolve(res);
          })
          .catch(res => { })
          .finally(res => { });
      });
    },
    // 获取县域配送板块数据
    getSameCityContent() {
      let data = {
        supplierId: this.data.baseGoodsInfo.supplierId
      };
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.supplierPostage.getSameCityContent, { params: data })
          .then(res => {
            this.sameCityContent = res.data;
            resolve(res);
          })
          .catch(res => {
            this.sameCityContent = {};
          })
          .finally(res => { });
      });
    },
    // 获取自提板块数据
    getSelfMentionContent() {
      let data = {
        supplierId: this.data.baseGoodsInfo.supplierId
      };
      return new Promise((resolve, reject) => {
        this.axios
          .get(URL.supplierPostage.getSelfMentionContent, { params: data })
          .then(res => {
            this.selfMentionContent = res.data;
            resolve(res);
          })
          .catch(res => {
            this.selfMentionContent = {};
            // reject(res);
          })
          .finally(res => { });
      });
    },
    // 选择某个村店铺
    selectSupplier(data) {
      this.data.baseGoodsInfo.freightTemplateId = "";
      this.data.baseGoodsInfo.isSupportPostage = 2;
      this.data.baseGoodsInfo.isSupportSameCity = 2;
      this.data.baseGoodsInfo.isSupportSelfMention = 2;
      this.getList();
      this.getSameCityContent();
      this.getSelfMentionContent();
    },
    // 选中某个邮寄快递模板
    selectMailTemplate(val) {
      let selectData = "";
      this.mailList.forEach(item => {
        if (item.id == val) {
          selectData = item;
          this.data.baseGoodsInfo.freightTemplateName = item.name;
        }
      });
      this.data.baseGoodsInfo.freightTemplateId = val;
      this.selectFreightTemplateData = selectData;
    },
    // 渲染暂不发货地区
    getNonDeliveryArea(val) {
      let list = JSON.parse(val);
      let text = "未配置暂不发货地区";
      if ((list || []).length > 0) {
        text = "";
        list.forEach(item1 => {
          item1.cityList.forEach(item2 => {
            item2.areaList.forEach(item3 => {
              text = text + item1.province + item2.city + item3 + "、";
            });
          });
        });
        text = text.slice(0, text.length - 1);
      }
      return text;
    },
    // 获取打开的配送方式
    getSupplierDelivery() {
      let self = this;
      self.axios
        .get(URL.supplierPostage.getSupplierDelivery)
        .then(res => {
          self.mailValue = res.data.isOpenPostage == 2;
          res.data.isOpenPostage == 2
            ? (self.data.baseGoodsInfo.isSupportPostage = 2)
            : null;
          self.cityValue = res.data.isOpenSameCity == 2;
          res.data.isOpenSameCity == 2
            ? (self.data.baseGoodsInfo.isSupportSameCity = 2)
            : null;
          self.pickUpValue = res.data.isOpenSelfMention == 2;
          res.data.isOpenSelfMention == 2
            ? (self.data.baseGoodsInfo.isSupportSelfMention = 2)
            : null;
          resolve(res);
        })
        .catch(res => { })
        .finally(res => { });
    },
    // 重置
    refreshBtn() {
      this.getList();
      this.getSameCityContent();
      this.getSelfMentionContent();
      this.getSupplierDelivery();
      this.$message({
        message: "刷新成功",
        type: "success"
      });
    },
    // 容器滚动条
    bindEventScroll() {
      setTimeout(() => {
        let homeMain = document.getElementById("supplierEditGoodsPages");
        homeMain.addEventListener("scroll", this.mainScrollEvent);
      }, 200);
    },
    removeEventScroll() {
      let homeMain = document.getElementById("supplierEditGoodsPages");
      homeMain.removeEventListener("scroll", this.mainScrollEvent);
    },
    mainScrollEvent(e) {
      let homeMain = document.getElementById("supplierEditGoodsPages");
      let top = homeMain.scrollTop;
      this.scrollTop = top;
      if (top > 300) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goBackTop(e) {
      let homeMain = document.getElementById("supplierEditGoodsPages");
      let top = homeMain.scrollTop;
      let spaceNumber = 50;
      let topSpace = top / spaceNumber;
      let timeOut = setInterval(() => {
        top -= topSpace;
        if (top < 0) top = 0;
        homeMain.scrollTop = top;
        if (top <= 0) clearInterval(timeOut);
      }, parseInt(200 / spaceNumber));
    },
    // 选择销售模式
    selectBusinessType(data) {
      this.data.baseGoodsInfo.wholeSaleType = data;
      this.data.baseGoodsInfo.supplierId = "";
    },
    /** 获取商品草稿详情 */
    getDraftGoodsDetails() {
      let data = {
        draftId: this.draftId
      };
      this.loading = true;
      return this.axios
        .get(URL.supplierGoods.draftData, { params: data })
        .then(res => {
          if (res.data.monitors && res.data.monitors.length > 0) {
            var dataInfo = res.data.monitors;
            this.numbers = dataInfo.length;
            this.monitorImg = dataInfo[0].img;
          }
          this.getEditDataSuccess(res.data);
          if (this.data.baseGoodsInfo.video) {
            this.data.baseGoodsInfo.video = JSON.parse(
              this.data.baseGoodsInfo.video
            );
          }
          this.loading = false;
          return Promise.resolve(res);
        })
        .catch(res => {
          this.$message.error("获取草稿失败，请稍后再试!");
          this.loading = false;
          return Promise.reject(res);
        });
    },
    // 获取商品信息
    getGoodsInfo() {
      let data = {
        goodsId: this.draftId
      };
      this.loading = true;
      return this.axios
        .get(URL.supplierGoods.goodsInfo, { params: data })
        .then(res => {
          this.goodsAttributeList = res.data.goodsAttributeList;

          if (res.data.monitors && res.data.monitors.length > 0) {
            var dataInfo = res.data.monitors;
            this.numbers = dataInfo.length;
            this.monitorImg = dataInfo[0].img;
          }

          this.getEditDataSuccess(res.data);
          if (this.data.baseGoodsInfo.video) {
            this.data.baseGoodsInfo.video = JSON.parse(
              this.data.baseGoodsInfo.video
            );
          }
          this.loading = false;
          this.data.baseGoodsInfo.supplierId = "";
          this.data.baseGoodsInfo.type = this.data.baseGoodsInfo.type ? this.data.baseGoodsInfo.type : 1;
          return Promise.resolve(res);
        })
        .catch(res => {
          this.$message.error("获取商品详情失败，请稍后再试!");
          this.loading = false;
          return Promise.reject(res);
        });
    },
    // 批发模式下导入商品数据
    commodityDataImport() {
      this.showGoodsDialog = true;
    },
    // 获取导入的数据
    saveObj(data) {
      let self = this;
      self.isDraft = false;
      self.isOfficial = true;
      self.draftId = data.id;
      self.isChannel = true;
      self.getGoodsInfo();
    },
    getEditDataSuccess(data = {}) {
      this.refreshData = true;
      if (!isObjectPure(data.baseGoodsInfo)) {
        data.baseGoodsInfo = returnBaseDataGoodsDetails();
      }
      data.baseGoodsInfo.isPresell = data.baseGoodsInfo.isPresell == 1 ? 1 : 2; // 初始化预告单选框是否开启
      // 编辑的预告时间列表需要解析
      if (Reflect.has(data.baseGoodsInfo, 'presellTimeJson')) {
        data.baseGoodsInfo.presellTimeList = JSON.parse(data.baseGoodsInfo?.presellTimeJson ?? '[]'); // 预告时间
      }

      let baseGoodsInfo = data.baseGoodsInfo;
      if (this.isChannel) {
        // 导入的时候清空商品id,这样就是新建
        baseGoodsInfo.id = "";
        if (this.isSupportWholesale == 2) {
          baseGoodsInfo.businessType = 2;
        } else {
          baseGoodsInfo.businessType = 1;
        }
        baseGoodsInfo.wholeSaleType = 1;
        baseGoodsInfo.wholeSaleRuleList = [
          {
            beginNum: 0,
            price: 0
          }
        ];
      }
      // 轮播图
      if (baseGoodsInfo.banners) {
        this.slideList = JSONParse(baseGoodsInfo.banners);
      } else {
        this.slideList = [];
      }
      if (baseGoodsInfo.posterImg) {
        this.posterList = baseGoodsInfo.posterImg
      } else {
        this.posterList = [];
      }
      // 图文详情图片
      if (baseGoodsInfo.detailsImages) {
        this.detailsImages = JSONParse(baseGoodsInfo.detailsImages);
      } else {
        this.detailsImages = [];
      }
      // 售后须知图
      if (baseGoodsInfo.refundExplainImages) {
        this.refundExplainImages = JSONParse(baseGoodsInfo.refundExplainImages);
      } else {
        this.refundExplainImages = [];
      }
      // 省市区id
      this.categoryValue = [
        baseGoodsInfo.firstCateId || "",
        baseGoodsInfo.secCateId || "",
        baseGoodsInfo.thirdCateId || ""
      ];
      // 按数量批发规则
      if (baseGoodsInfo.wholeSaleRule) {
        baseGoodsInfo.wholeSaleRuleList = JSONParse(
          baseGoodsInfo.wholeSaleRule
        );
        delete baseGoodsInfo.wholeSaleRule;
      }
      // skuItemList
      data.skuItemList.forEach(res => {
        res.isSelect = true;
        res.skuItemValList.forEach(item => {
          item.isSelect = true;
        });
      });
      // goodsSkuList
      data.goodsSkuList.forEach(res => {
        res.isSelect = true;
      });

      this.data = data;
      this.refreshData = false;
      this.getGoodsEditConfig();
    },
    /** 选择基础分类、品牌 */
    showSelectCategoryAndBrand() {
      this.showDialogSelectCategory = true;
    },
    // 选择基础分类、品牌成功
    changeSelectCategoryAndBrandSuccess(res) {
      this.categoryValue = res.categoryValue;
      this.categoryText = res.categoryText;
      this.categoryData = res.categoryData;
      this.brandData = res.brandData;
      let [firstCateId, secCateId, thirdCateId] = res.categoryValue;

      Object.assign(
        this.data.baseGoodsInfo,
        {
          brandId: res.brandData.id,
          brandName: res.brandData.name,
          brandLogo: res.brandData.logo,
          cateDetails: res.categoryText.join(">")
        },
        {
          firstCateId,
          secCateId,
          thirdCateId
        }
      );
      this.getGoodsEditConfig();
    },
    /** 获取商家编辑商品时候的配置-扣点什么的 */
    getGoodsEditConfig() {
      let baseGoodsInfo = this.data.baseGoodsInfo;
      if (this.isSupportWholesale == 2) {
        this.data.baseGoodsInfo.businessType = 2;
      } else {
        this.data.baseGoodsInfo.businessType = 1;
      }

      let data = {
        firstCateId: baseGoodsInfo.firstCateId,
        secCateId: baseGoodsInfo.secCateId,
        thirdCateId: baseGoodsInfo.thirdCateId,
        brandId: baseGoodsInfo.brandId
      };
      this.loading = true;
      return this.axios
        .get(URL.supplierGoods.getGoodsEditConfig, { params: data })
        .then(res => {
          this.loading = false;
          this.$store.commit("setGoodsEditConfig", res.data);
          return Promise.resolve(res);
        })
        .catch(res => {
          this.$message.error("获取配置失败，请稍后再试!");
          this.loading = false;
          return Promise.reject(res);
        });
    },
    /**
     * 显示、隐藏‘选择运费模板’弹窗
     */
    showSelectPostage() {
      this.showDialogSelectPostage = true;
    },
    hideSelectPostage() {
      this.showDialogSelectPostage = false;
    },
    // 跳转运费模板
    goFreightTemplate() {
      let routeUrl = this.$router.resolve({ path: "/newSupplierPostage" });
      window.open(routeUrl.href, "_blank");
    },
    selectPostageItem(row) {
      this.data.baseGoodsInfo.freightTemplateName = row.name;
      this.data.baseGoodsInfo.freightTemplateId = row.id;
      this.data.baseGoodsInfo.freightTemplateType = row.type;
      // this.$refs['baseGoodsInfo.freightTemplateId'].clearValidate();
    },
    // 商品提交审核
    saveReviewGoods() {
      let data = deepsClone(this.data);

      data.skuItemList = this.filterSkuItemList(data.skuItemList);
      data.goodsSkuList.forEach(v => {
        v.basicSalesSwitch = data.baseGoodsInfo.basicSalesSwitch;
      });
      data.goodsSkuList = this.filterGoodsSkuList(data.goodsSkuList);
      if (this.intoDataList) {
        data.baseGoodsInfo.monitorIds = this.intoDataList.monitorIds;
        data.baseGoodsInfo.isMonitor = this.intoDataList.isMonitor;
      }
      if (
        data.baseGoodsInfo.isSupportPostage == 2 &&
        data.baseGoodsInfo.isSupportSameCity == 2 &&
        data.baseGoodsInfo.isSupportSelfMention == 2
      ) {
        this.$message({
          message: "请至少选择一种配送方式",
          type: "warning"
        });
        return;
      }

      let siteType = JSON.parse(localStorage.getItem("user_info")).siteType;
      if (siteType == 3) {
        data.baseGoodsInfo.supplierId = "";
        data.baseGoodsInfo.supplierName = "";
      }
      data.baseGoodsInfo.posterImg = this.posterList
      if (this.posterList.length == 0) {
        this.$message.error('请添加海报封面图')
        return
      }
      this.loading = true;
      return this.axios
        .post(URL.supplierGoods.saveReviewGoods, data)
        .then(res => {
          this.$message.success("提交审核成功");
          this.allowedLeave = true;

          let routeData = this.originPageUrL
            ? {
              path: this.originPageUrL
            }
            : {
              name: "supplierGoodsReview"
            };
          this.$router.replace(routeData).then(res => {
            this.saveSuccess();
          });
        })
        .catch(res => { })
        .finally(() => {
          this.loading = false;
        });
    },
    // 商品保存草稿
    saveDraftGoods() {
      this.loading = true;
      let data = deepsClone(this.data);
      data.skuItemList = this.filterSkuItemList(data.skuItemList);
      data.goodsSkuList.forEach(v => {
        v.basicSalesSwitch = data.baseGoodsInfo.basicSalesSwitch;
      });
      data.goodsSkuList = this.filterDraftGoodsSkuList(data.goodsSkuList);
      data.baseGoodsInfo.posterImg = this.posterList
      this.axios
        .post(URL.supplierGoods.saveGoodsDraft, data)
        .then(res => {
          this.$message.success("保存草稿成功");
          this.allowedLeave = true;

          let routeData = this.originPageUrL
            ? {
              path: this.originPageUrL
            }
            : {
              name: "supplierGoodsDraft"
            };
          this.$router.replace(routeData).then(res => {
            this.saveSuccess();
          });
        })
        .catch(res => { })
        .finally(res => {
          this.loading = false;
        });
    },
    saveSuccess() { },
    // 提交前筛选工作
    filterSkuItemList(list = this.data.skuItemList) {
      list = deepsClone(list);
      list = list.filter(res => {
        if (res.isSelect === false) return false;
        if (!res.skuItemName) return false;
        if (!res.skuItemValList.length) return false;
        res.skuItemValList = res.skuItemValList.filter(item => {
          if (item.isSelect === false) return false;
          return !!item.skuItemValName;
        });
        return res.skuItemValList.length;
      });
      return list;
    },
    // 对SKU的数据处理，保留两位小数
    filterGoodsSkuList(list = this.data.goodsSkuList) {
      list = deepsClone(list);
      list = list.filter(res => {
        if (res.isSelect === false) return false;
        if (!res.name) return false;
        res.stockNum = toInteger(res.stockNum);
        if (this.isChannel) {
          res.id = "";
          res.goodsId = "";
        }

        if (this.data.baseGoodsInfo.wholeSaleType == 2) {
          res.price = toNumberPrice(
            this.data.baseGoodsInfo.wholeSaleRuleList[0].price
          );
          res.costPercent = toNumberPrice(2); // 百分之二
          res.cost = toNumberPrice(
            parseFloat(res.price) *
            parseFloat(this.$store.state.minB2bCostPercent / 100)
          );
        } else if (this.data.baseGoodsInfo.wholeSaleType == 3) {
          res.price = toNumberPrice(res.price);
          res.costPercent = toNumberPrice(2); // 百分之二
          res.cost = toNumberPrice(
            parseFloat(res.price) *
            parseFloat(this.$store.state.minB2bCostPercent / 100)
          );
        } else {
          res.price = toNumberPrice(res.price);
          res.costPercent = toNumberPrice(res.costPercent);
          res.cost = toNumberPrice(res.cost);
          res.purchasePrice = toNumberPrice(res.purchasePrice);
        }
        res.purchasePrice = toNumberPrice(res.purchasePrice);
        if (!res.marketPrice) res.marketPrice = '';
        res.marketPrice = toNumberPrice(res.marketPrice);
        return true;
      });
      return list;
    },
    filterDraftGoodsSkuList(list = this.data.goodsSkuList) {
      list = deepsClone(list);
      list = list.filter(res => {
        if (res.isSelect === false) return false;
        if (!res.name) return false;
        return true;
      });
      return list;
    },
    // 提交前校验工作
    submitBeforeCheckSkuItemList(list = this.data.skuItemList) {
      list = this.filterSkuItemList(list);
      // if(!list.length) return true;
      return true;
    },
    submitBeforeCheckGoodsSkuList(list = this.data.goodsSkuList) {
      list = this.filterGoodsSkuList(list);
      if (!list.length) {
        this.$message.error("商品sku为空，缺失“默认SKU”");
        return false;
      }
      let judge = list.every(res => {
        let stockNum = res.stockNum; // 库存
        let price = res.price; // 售价
        let purchasePrice = res.purchasePrice; // 供货成本价
        let cost = res.cost; // 平台扣点金额
        let costPercent = res.costPercent; // 平台扣点百分比
        // let marketPrice = res.marketPrice; // 市场价
        let cover = res.cover; // 封面
        let basePurchasePrice = res.basePurchasePrice; // 采购价
        let circulationCost = res.circulationCost; // 流通成本
        let operationCost = res.operationCost; // 运营成本
        let grossProfitRate = res.grossProfitRate; // 平台毛利率
        let marketPrice = res.marketPrice; // 划线价
        // let cover = res.cover; // 封面
        console.log(res, 'res')
        if (!isStringNumber(basePurchasePrice)) {
          this.$message.error(`${res.name}-SKU 采购价为空`);
          return false;
        }
        if (!isStringNumber(circulationCost)) {
          this.$message.error(`${res.name}-SKU 流通成本为空`);
          return false;
        }
        if (!isStringNumber(operationCost)) {
          this.$message.error(`${res.name}-SKU 运营成本为空`);
          return false;
        }
        if (!isStringNumber(grossProfitRate)) {
          this.$message.error(`${res.name}-SKU 平台毛利率为空`);
          return false;
        }
        if (grossProfitRate < 10 || grossProfitRate > 99) {
          this.$message.error(`${res.name}-SKU 平台毛利率取值不正确`);
          return false;
        }
        if (!isStringNumber(marketPrice)) {
          this.$message.error(`${res.name}-SKU 划线价为空`);
          return false;
        }
        if (res.marketPrice < res.price) {
          this.$message.error(`划线价不得低于销售价`);
          return false;
        }
        // =
        if (!isStringNumber(stockNum)) {
          this.$message.error(`${res.name}-SKU 库存为空`);
          return false;
        }
        // 这是按照数量的批发模式-只校验库存和图片就行
        if (this.data.baseGoodsInfo.wholeSaleType != 2) {
          if (!isStringNumber(price)) {
            this.$message.error(`${res.name}-SKU 售价为空`);
            return false;
          }
          // 这是按照规格的批发模式-只校验库存和售价和图片就行
          if (this.data.baseGoodsInfo.wholeSaleType != 3) {
            if (!isStringNumber(purchasePrice)) {
              this.$message.error(`${res.name}-SKU 供货价为空`);
              return false;
            }
            if (!isStringNumber(costPercent)) {
              this.$message.error(`${res.name}-SKU 平台扣点百分比 为空`);
              return false;
            } else if (
              parseFloat(costPercent) < this.$store.state.minCostPercent
            ) {
              this.$message.error(
                `${res.name}-SKU 平台扣点百分比 < ${this.$store.state.minCostPercent}%`
              );
              return false;
            }
            if (price < purchasePrice) {
              this.$message.error(`${res.name}-SKU 售价 < 供货价`);
              return false;
            }
            if (isStringNumber(marketPrice)) {
              if (marketPrice === 0) {
              } else if (marketPrice < price) {
                this.$message.error(`${res.name}-SKU 划线价 < 售价`);
                return false;
              }
            }
          }
        }
        if (!cover) {
          this.$message.error(`${res.name}-SKU 封面 为空`);
          return false;
        }
        return true;
      });
      if (!judge) return false;
      return true;
    },
    /**
     * 表单提交及重置
     * @param formName
     */
    submitForm(formName = this.formNameEditGoods) {
      // 按钮：提交审核
      this.$refs[formName].validate((valid, obj) => {
        if (!valid) {
          this.$message.error("请检查数据是否填写完整");

          scrollToView(this, obj);
          return false;
        }
        if (!this.submitBeforeCheckGoodsSkuList()) {
          scrollToView(this, { goodsSkuListRef: {} });
          return false;
        }
        // if((this.data.skuItemList.length > 2) && (this.data.baseGoodsInfo.businessType == 2)) {
        //   // 批发模式sku组不可大于个
        //   this.$message.error("批发模式下，SKU组不可大于两组，请调整后提交");
        //   return false
        // }
        this.saveReviewGoods();
      });
    },
    submitFormDraft(formName = this.formNameEditGoods) {
      // 按钮：保存草稿
      if (this.data.baseGoodsInfo.calculateVolume) {
        if (
          !/^([1-9]\d*|0)(?:\.\d{0,3}[1-9])?$/.test(
            this.data.baseGoodsInfo.calculateVolume
          )
        ) {
          this.$message.error("请输入正数数值，最多支持四位小数");
          return false;
        }
      }
      if (this.data.baseGoodsInfo.calculateWeight) {
        if (
          !/^([1-9]\d*|0)(?:\.\d{0,3}[1-9])?$/.test(
            this.data.baseGoodsInfo.calculateWeight
          )
        ) {
          this.$message.error("请输入正数数值，最多支持四位小数");
          return false;
        }
      }
      this.saveDraftGoods();
    },
    resetForm(formName = this.formNameEditGoods) {
      this.$refs[formName].resetFields();
    },
    previewSkuTagClick(index, value) {
      this.$set(this.currentSelectSku, index, value);
    },
    changeSelectedSkuHandle(selectedList = this.currentSelectSku) {
      if (selectedList.length === this.data.skuItemList.length) {
        let v = selectedList.map(res => res.skuItemValName).join("_");
        this.selectedSku = this.data.goodsSkuList.filter(
          res => res.name == v
        )[0];
      } else {
        this.selectedSku = undefined;
      }
    },
    changePresellRadio(name) {
      //清除预告验证规则提示
      if (this.data.baseGoodsInfo.isPresell != 1 && name) {
        this.$refs[name].resetField();
      }
    },
    changeVodVideoFiles(videoFile) {
      this.data.baseGoodsInfo.video = videoFile;
    }
  },
  computed: {
    // 特殊时段
    specialHoursText() {
      let text = "";
      if (this.sameCityContent.supplierSameCityDelivery.specialTimeWay == 1) {
        text = "无特殊时段费用";
      } else {
        text = "";
        JSON.parse(
          this.sameCityContent.supplierSameCityDelivery.specialTimeFeeRule
        ).forEach(item => {
          text =
            text +
            `${item.startTime} - ${item.endTime} 加 ${item.increaseAmount}元 ;`;
        });
      }
      return text;
    },
    // 计价状态转化
    calculateWay() {
      let calculateWay = "";
      if (this.sameCityContent.supplierSameCityDelivery.calculateWay == 1) {
        calculateWay = "免费配送";
      } else if (
        this.sameCityContent.supplierSameCityDelivery.calculateWay == 2
      ) {
        calculateWay = "满额起送";
      } else {
        calculateWay = `阶梯计费-下单满${this.sameCityContent.supplierSameCityDelivery.fullAmount}元起送, ${this.sameCityContent.supplierSameCityDelivery.startCalculateDistance}公里内${this.sameCityContent.supplierSameCityDelivery.startDistanceFee}元, 每增加${this.sameCityContent.supplierSameCityDelivery.calculateFeeStepDistance}公里加${this.sameCityContent.supplierSameCityDelivery.increaseStepFee}元`;
      }
      return calculateWay;
    },
    canOpenEdit() {
      // return true;
      return !!this.data.baseGoodsInfo.brandId && !this.refreshData;
    },
    categoryAndBrandText() {
      let text = "";
      if (this.categoryText) {
        text += this.categoryText.join("/");
        if (this.brandData.name) {
          text += "/";
          text += this.brandData.name;
        }
      }
      return text;
    },
    previewSkuImage() {
      let df = this.slideList[0];
      if (!this.selectedSku) {
        //还没选sku，显示
        return df;
      } else if (this.selectedSku) {
        return this.selectedSku.cover ? this.selectedSku.cover : df;
      }
      return "";
    },
    previewSkuTitle() {
      if (this.data.skuItemList.length) {
        return this.data.skuItemList.map(res => res.skuItemName).join(" ");
      }
      return "默认";
    },
    previewSkuPrice() {
      if (!this.selectedSku && this.data.goodsSkuList.length) {
        //还没选sku，显示最低价和最高价
        let list = deepsClone(this.data.goodsSkuList);
        let priceList = list
          .sort(
            (res1, res2) =>
              parseFloat(res1["price"]) - parseFloat(res2["price"])
          )
          .map(item => parseFloat(item.price));
        let min = priceList[0];
        let max = priceList[priceList.length - 1];
        if (isNaN(min)) return "¥0";
        return max > min ? `¥${min} ~ ¥${max}` : `¥${min}`;
      } else if (this.selectedSku) {
        let price = this.selectedSku.price ? this.selectedSku.price : 0;
        return `¥${price}`;
      }
      return "¥0";
    },
    previewSkuStockNum() {
      if (!this.selectedSku && this.data.goodsSkuList.length) {
        //还没选sku，显示库存和
        let sum = this.data.goodsSkuList
          .map(item => parseInt(item.stockNum))
          .reduce((prev, next) => {
            return prev + next;
          });
        if (isNaN(sum)) return "库存 0";
        return `库存 ${sum}`;
      } else if (this.selectedSku) {
        let stockNum = this.selectedSku.stockNum
          ? this.selectedSku.stockNum
          : 0;
        return `库存 ${stockNum}`;
      }
      return "库存 0";
    },
    // 县域配送展示数据
    getDeliveryArea() {
      let text = "";
      if (this.sameCityContent.address) {
        if (this.sameCityContent.isOpenSameCity == 2) {
          text = "县域配送";
        } else {
          if (
            this.sameCityContent.supplierSameCityDelivery.supportAddressType ==
            1
          ) {
            text =
              this.sameCityContent.address.province +
              this.sameCityContent.address.city;
          } else {
            text =
              this.sameCityContent.address.province +
              this.sameCityContent.address.city +
              this.sameCityContent.address.area;
          }
        }
      } else {
        text = "暂未设置县域配送发货地";
      }
      return text;
    },
    //预售商品时间参数配置
    presellPickerOptions() {
      const that = this;
      return {
        disabledDate(time) {
          const today = that.$dayjs().format('YYYY-MM-DD');
          return (
            time.getTime() < that.$dayjs(today).valueOf()
          );
        }
      };
    },
    //获取左边banner视频信息
    getBannerVideoVal() {
      const videoVal = this.data.baseGoodsInfo?.video;
      return Object.keys(videoVal).length > 0 ? videoVal : null
    }
  },
  watch: {
    slideList(newVal) {
      if (newVal instanceof Array && newVal.length > 0) {
        this.data.baseGoodsInfo.banners = JSONStringify(newVal);
        this.data.baseGoodsInfo.cover = newVal[0];
        // this.$refs["baseGoodsInfo.banners"].clearValidate();
      } else {
        this.data.baseGoodsInfo.banners = "";
        this.data.baseGoodsInfo.cover = "";
      }
    },
    detailsImages(newVal) {
      if (newVal instanceof Array) {
        this.data.baseGoodsInfo.detailsImages = JSONStringify(newVal);
      } else {
        this.data.baseGoodsInfo.detailsImages = "";
      }
    },
    refundExplainImages(newVal) {
      if (newVal instanceof Array) {
        this.data.baseGoodsInfo.refundExplainImages = JSONStringify(newVal);
      } else {
        this.data.baseGoodsInfo.refundExplainImages = "";
      }
    },
    "data.goodsSkuList": {
      //深度监听，可监听到对象、数组的变化
      handler(newVal, oldVal) {
        this.changeSelectedSkuHandle();
      },
      deep: true
    },
    currentSelectSku(newValue) {
      this.changeSelectedSkuHandle(newValue);
    }
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`

    if (this.allowedLeave) {
      next();
    } else if (this.data.baseGoodsInfo.brandId) {
      this.$confirm(
        "是否离开页面？记得保存哦，未保存时，编辑的内容可能丢失哦~",
        "提示"
      ).then(res => {
        next();
      });
    } else {
      next();
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/css/data";
@import (reference) "../../../assets/css/defined";

.shadow-box {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
    color: #fff;
  }
}

.monitor-img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;

  img {
    width: 100%;
    height: 100%;
  }
}

.monitor-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.monitor {
  width: 100px;
  height: 100px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  .el-icon-plus {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 28px;
  }
}

.custom-video {
  width: 100%;
  height: 100%;
  position: relative;

  .videoPlayerBox {
    width: 100%;
    height: 100%;
  }
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 45px;
  width: 45px;
}

.el-form-item--label,
.form-item-label-row .el-form-item__label {
  text-align: left;
  width: 100% !important;
  font-size: @font-size-subheading;
  color: @color-info;
}

.el-form-item--brief {
  font-size: @font-size-main-text;
  color: @color-info;
}
</style>
<style scoped lang="less">
@import "../../../assets/css/data";
@import (reference) "../../../assets/css/defined";

.carousel_video {
  width: 375px !important;
  height: 375px !important;
}

.pages {
  background-color: transparent;
}

.goods-mobile-model-warp {
  position: relative;
  width: 375px;
  margin-right: @margin-primary;

  .goods-mobile-model {
    background-color: white;
    width: 100%;
    height: 812px;
    border-radius: @border-radius-circle;

    &.absolute {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .goods-preview {
      border-radius: @border-radius-circle;
      height: 812px;
      background-color: #f6f7f8;
      overflow: hidden;
      color: #353535;

      * {
        box-sizing: border-box;
      }

      .gray-text {
        color: #7e8081;
      }

      .scroll-view {
        box-sizing: border-box;
        position: relative;
        height: 812px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 64px;
        margin-right: -6px;

        .scroll-view-body {
          .body-item:after {
            display: block;
            content: " ";
            margin-top: 10px;
            height: 1px;
            border-style: solid;
            border-color: #e3e3e3;
            border-width: 0 0 1px 0;
          }

          .goods-base {
            padding: 10px;

            .product-price {
              color: #ff4f4f;
              padding: 12px;
              font-size: 24px;
              line-height: 28px;
              font-weight: bolder;
            }

            .product-goods-name {
              color: #333333;
              font-weight: bold;
              font-size: 18px;
            }

            .product-goods-brief {
              font-size: 12px;
            }
          }

          .detail-text {
            margin: 0 16px;
            white-space: pre-line;
            box-sizing: border-box;
            min-height: 44px;
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        display: flex;
        font-size: 14px;
        border-top: 1px solid #e6e7e8;
        background-color: #f6f7f8;

        i {
          font-size: 24px;
        }

        .label-text {}

        .add-cart {
          cursor: pointer;
          font-weight: 600;
          color: #ff4f4f;
          padding: 5px;
          border: 2px solid #ff4f4f;
          border-radius: 12px;
        }

        .buy-now {
          color: white;
          cursor: pointer;
          font-weight: 600;
          padding: 7px;
          border-radius: 12px;
          background-color: #ff4f4f;
        }
      }

      .sku-dialog {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        .sku-mask {
          position: absolute;
          top: 0;
          width: 100%;
          height: 812px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: @border-radius-circle;
        }

        .sku-body {
          position: absolute;
          left: 0;
          bottom: 60px;
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background: #fff;
          z-index: 9;
          overflow: hidden;

          .close-button {
            width: 100%;
            height: 60px;
          }

          .close-button:after {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 10px;
            width: 24px;
            height: 24px;
            background-size: cover;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E %3Cdefs%3E %3Cpath id='fb7be2ee-029c-4bf0-8a14-04c34eeb13f3-a' d='M8 6.943L1.807.75.75 1.807 6.943 8 .75 14.193l1.057 1.057L8 9.057l6.193 6.193 1.057-1.057L9.057 8l6.193-6.193L14.193.75z'/%3E %3C/defs%3E %3Cuse fill-opacity='.9' fill-rule='evenodd' transform='translate(4 4)' xlink:href='%23fb7be2ee-029c-4bf0-8a14-04c34eeb13f3-a'/%3E%3C/svg%3E");
          }

          .sku-body-content {
            .sku-body-content-item {
              padding: 0 15px;
            }

            .sku-preview-img {
              width: 96px;
              height: 96px;
              margin-right: 15px;
            }

            .sku-preview-price {
              color: #ff4f4f;
              font-size: 24px;
              line-height: 28px;
              font-weight: bolder;
            }

            .sku-preview-stock {
              margin-top: 10px;
            }

            .sku-count {
              margin-top: 10px;
              padding: 10px 0;
              position: relative;

              .sku-count-ctrl {
                >div {
                  font-size: 14px;
                  display: inline-flex;
                  box-sizing: border-box;
                  height: 32px;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  vertical-align: middle;
                }

                .count-ctrl-btn {
                  cursor: pointer;

                  span {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    border-radius: 50%;
                    background-color: #f6f7f8;
                  }
                }

                .count-ctrl-input {
                  width: 36px;
                  font-size: 14px;
                  display: inline-flex;
                  box-sizing: border-box;
                  height: 32px;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  vertical-align: middle;
                }
              }
            }

            .sku-count:before {
              content: " ";
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              height: 1px;
              border-top: 1px solid #e6e7e8;
              color: #f6f7f8;
              transform-origin: 0 0;
              transform: scaleY(0.5);
              right: -16px;
            }

            .sku-list {
              .sku-item {
                padding: 10px 0;

                .item-title {
                  margin-bottom: 10px;
                }

                .item-tags {
                  .item-tag {
                    font-size: 12px;
                    padding: 5px 10px;
                    margin-right: 8px;
                    background-color: #f6f7f8;
                    border-radius: 10px;
                    cursor: pointer;

                    &.active {
                      color: #f6f7f8;
                      background-color: #ff4f4f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.edit-goods-wrap {
  width: 800px;
  height: 750px;
}

.edit-goods-module {
  border-radius: @border-radius-big;
  background-color: white;
  padding: @padding-primary;
  margin-bottom: @margin-primary;
}

.edit-mail-data-wrap {
  padding: 20px 10px;
  background: #f5f7fa;
  margin-top: 5px;
}

.edit-mail-data {
  margin-top: 10px;
  align-items: center;
  font-size: 14px;
}

.edit-goods-header {
  /*padding: @padding-primary 0;*/
  margin-bottom: @margin-primary;
  .defined-flex-item();

  .title {
    font-size: @font-size-heading;
    color: @font-color-heading-text;
    font-weight: bold;
  }

  .category-text {
    font-size: @font-size-main-text;
  }
}

.textarea-goods-details {
  width: 100%;
  height: 100px;
  padding: 10px;
  line-height: 2;
  box-sizing: border-box;
}

.postage-item {}

.postage-label {
  display: inline-block;
  width: 80px;
  text-align: justify;
  text-align-last: justify;
  margin-right: 10px;
}

.edit-goods-action-wrap {
  .el-button {
    width: 112px;
  }

  .edit-goods-action {}
}

.presell-main {
  .presell-tip {
    b {
      font-size: 14px;
    }

    p {
      margin: 0;
      margin-left: 10px;
      font-size: 13px;
    }
  }
}
</style>
<style lang="less">
.scroll-view-box {
  z-index: 0;

  .el-carousel__arrow {
    top: 60% !important;
    background-color: #787878;
  }
}
</style>
