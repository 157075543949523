<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closedCallback">
    <div class="select-category-header">没有适合的类目和品牌？
      <!-- <el-link type="primary" href="/supplierBrand?showDrawer=1">前往添加</el-link> -->
    </div>
    <div class="select-category-wrap" v-loading="loading">
      <select-category-item title="一级类目"
                            v-model="categoryListValueOne"
                            :data="categoryListOne"></select-category-item>
      <select-category-item title="二级类目"
                            v-model="categoryListValueTwo"
                            :data="categoryListTwo" ></select-category-item>
      <select-category-item title="三级类目"
                            v-model="categoryListValueThree"
                            :data="categoryListThree" ></select-category-item>
      <select-category-item title="品牌"
                            v-model="brandListValue"
                            :data="brandList"
                            :leaf="true"></select-category-item>
    </div>
    <div class="select-category-function flex-item">
      <el-alert class="content"
                title="修改基础分类,会重置“非销售属性”、“商品SKU”等已编辑属性"
                :closable="false"
                show-icon
                type="warning">
      </el-alert>
      <el-button type="primary"
                 class="right"
                 @click="submitNext"
                 :disabled="!brandListValue">下一步</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {templateElDialog} from '../../public/template/template-el-dialog'
  import  selectCategoryItem from './selectCategoryItem'
  import {disposeTreeData} from '../../../assets/js/goodsClassify'
  import {URL} from '../../../config'
  import {isEmpty} from '../../../assets/js/utils'

  export default {
    name: 'selectCategoryAndBrand',
    mixins:[templateElDialog],
    components:{
      selectCategoryItem,
    },
    props:{
      title:{
        type:String,
        default:'选择分类/品牌'
      },
      customClass:{
        type:String,
        default:'select-category-dialog'
      },
      closeOnClickModal: { // 是否可以通过点击 modal 关闭 Dialog
        type: Boolean,
        default: false,
      },
      defaultCategoryList: { // 默认选中三级分类id[id,id,id]
        type: Array,
      },
      defaultBrandId:{ // 默认选中品牌id[id,id,id]
        type: String,
      },
    },
    data(){
      return {
        loading:false,
        /* 分类 */
        categoryList:[],
        // categoryListIndex:[],
        // categoryListValue:[],
        categoryListValueOne:'',
        categoryListValueTwo:'',
        categoryListValueThree:'',
        /* 品牌 */
        brandList:[],
        brandListValue:'',
        listCateGoryOrigin: [],
        listCategory:[]
      }
    },
    mounted () {
      this.getBaseCategoryList().finally(res=>{
        this.setCategoryListValue();
        this.brandListValue = this.defaultBrandId;
      })
    },
    methods:{
      /**
       * 设置默认三级分类值 */
      setCategoryListValue(list = this.defaultCategoryList){
        let [one,two,three] = list;
        this.categoryListValueOne = one;
        this.categoryListValueTwo = two;
        this.categoryListValueThree = three;
      },
      /**
       * 判断类表中是否存在 指定项*/
      includesItem(list=[],itemId){
        return  list.some(res=>res.id === itemId);
      },
      /**
       * 获取分类列表
       */
      getBaseCategoryList(){
        let data = {
        };
        this.loading = true;
        // return this.axios.get(URL.supplierGoods.getSupplierBrandCateList,{params:data}).then(res=>{
        //   this.categoryList = res.data;
        //   this.loading = false
        //   return Promise.resolve(res);
        // }).catch(res=>{
        //   this.categoryList = []
        //   this.loading = false
        //   return Promise.reject(res);
        // })
        return this.axios.get(URL.supplierBrand.searchCommonCategory,{params:{}}).then(res=>{
          if(res.code === 0){
            this.categoryList= res.data;
            this.listCategory = disposeTreeData(res.data,2);
            this.loading = false
          }
          return Promise.resolve(res);
        }).catch(()=>{
          this.categoryList = []
          this.loading = false
          return Promise.reject(res);
        })
      },
      /**
       * 根据选择的基础分类查找可选品牌
       */
      getCategoryOfBrandList(id){
        if(isEmpty(id)) return false;
        let data = {
          thirdCateId: id
        }
        this.loading = true
        return this.axios.get(URL.supplierGoods.getSupplierBrandByCate,{
          params:data,
        }).then(res=>{
          res.data.forEach(res=>{
            res.id = res.brandId
            res.name = res.brandName
            delete res.brandId;
          })
          this.loading = false
          this.setBrandList(res.data);
        }).catch(res=>{
          this.setBrandList();
          this.loading = false
        })
      },
      setBrandList(list = []){
        if(Array.isArray(list)){
          this.brandList = list;
        }else {
          this.brandList = [];
        }
      },
      /**
       * 下一步
       * */
      submitNext(){
        if(isEmpty(this.categoryListValueOne)) return this.$message.error('一级类目id异常');
        if(isEmpty(this.categoryListValueTwo)) return this.$message.error('二级类目id异常');
        if(isEmpty(this.categoryListValueThree)) return this.$message.error('三级类目id异常');
        if(isEmpty(this.brandList)) return this.$message.error('品牌id异常');
        let categoryValue = [this.categoryListValueOne,this.categoryListValueTwo,this.categoryListValueThree];
        let categoryData = categoryValue.map(id=>{
          let list = this.categoryList.filter(res=>res.id === id);
          if(list.length){
            return list[0]
          }else{
            return null
          }
        });
        let categoryText = categoryData.map(res=>res.name);
        let brandValue = this.brandListValue;
        let brandData = this.brandList.filter(res=>res.id === brandValue)[0]||{};
        this.$emit('success',{
          categoryValue,
          categoryText,
          categoryData,
          brandValue,
          brandData,
        })
        this.visibleValue = false;
      }
    },
    computed:{
      categoryListOne(){
        return this.categoryList.filter(res=>res.parentId === '0');
      },
      categoryListTwo(){
        let list = [];
        if(!this.categoryListValueOne) return list;
        list = this.categoryList.filter(res=>res.parentId === this.categoryListValueOne);
        return list
      },
      categoryListThree(){
        let list = [];
        if(!this.categoryListValueTwo) return list;
        list = this.categoryList.filter(res=>res.parentId === this.categoryListValueTwo);
        return list
      },
    },
    watch:{
      defaultCategoryList(newVal){
        this.setCategoryListValue(newVal);
      },
      defaultBrandId(newVal){
        // this.brandListValue = newVal;
      },
      categoryListOne(newVal){
        if(this.includesItem(newVal,this.categoryListValueOne)) return false;
        this.categoryListValueOne = '';
      },
      categoryListTwo(newVal){
        if(this.includesItem(newVal,this.categoryListValueTwo)) return false;
        this.categoryListValueTwo = '';
      },
      categoryListThree(newVal){
        if(this.includesItem(newVal,this.categoryListValueThree)) return false;
        this.categoryListValueThree = '';
      },
      categoryListValueThree(newVal){
        if(newVal){
          this.getCategoryOfBrandList(this.categoryListValueThree).finally(()=>{
            if(this.includesItem(this.brandList,this.brandListValue)) return false;
            this.brandListValue = '';
          });
        }else{
          this.brandListValue = '';
          this.setBrandList();
        }
      },
    }
  }
</script>

<style lang="less">
  @import "../../../assets/css/data";
  .select-category-dialog{
    .el-dialog__body{
      padding: @padding-primary 0;
    }
  }
</style>
<style scoped lang="less">
  @import "../../../assets/css/data";
  .select-category-header{
    margin-bottom: @margin-primary;
    padding: 0 @padding-primary;
  }
  .select-category-wrap{
    height: 400px;
    display: flex;
  }
  .select-category{
    padding: 0 @padding-primary;
    & + & {
      border-left: @border-width-solid solid @border-color-one;
    }
  }
  .select-category-function{
    margin-top: @margin-primary;
    padding: 0 @padding-primary;
    /*line-height: 40px;*/
  }
</style>
