<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closedCallback">
    <el-form @submit.native.prevent :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-select v-model="searchData.searchType"
                   placeholder="请选择"
                   style="width: 120px">
          <el-option
            v-for="item in searchKeyType"
            :disabled="item.disabled"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input class=""
                  placeholder="搜索关键字"
                  prefix-icon="el-icon-search"
                  v-model="searchData.searchValue"
                  @keyup.enter.native="search"
                  style="width: 260px">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-form-item>

    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="模板名称"
        width="250">
      </el-table-column>

      <el-table-column
        prop="isFree"
        label="是否包邮"
        width="160">
        <template slot-scope="scope">
          {{isPostageFree[scope.row.isFree]}}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <span class="color-danger" v-if="value === scope.row.id">当前</span>
          <template v-else>
            <el-button type="primary" size="mini" @click="selectPostageItem(scope.row)">选择当前模板</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import {templateElDialog} from '../../public/template/template-el-dialog'
  import {URL} from '../../../config'
  import {disposeSearchData} from '../../../assets/js/utils'
  import {isPostageFree} from '../../../assets/js/status'

  export default {
    name: 'selectPostage',
    mixins:[templateElDialog],
    props:{
      value:{
        type:String,
      },
      title:{
        type:String,
        default:'选择运费模板'
      },
      customClass:{
        type:String,
        default:'select-postage'
      },
    },
    data(){
      return {
        isPostageFree,
        searchKeyType:[
          {id: 'name', name: '模板名称',disabled:true},
        ],
        searchData:{
          searchType:'name',
          searchValue:'',
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页显示得条数
      }
    },
    created(){
      this.getList();
    },
    methods:{
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierPostage.list,{params:data}).then(res=>{
            this.tableData = res.data;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      selectPostageItem(row){
        this.$emit('input',row.id);
        this.$emit('change',{
          name:row.name,
          id:row.id,
          type:row.billingType
        });
        this.visibleValue = false;
      },
    },

  }
</script>

<style scoped>

</style>
