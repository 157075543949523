<template>
  <div class="select-category">
    <el-form :inline="true"
             @submit.native.prevent
             class="select-category-search">
      <el-form-item>
        <el-input class=""
                  :placeholder="'搜索'+title"
                  prefix-icon="el-icon-search"
                  v-model="keywords">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="select-category-list">
      <template v-if="data.length">
        <div class="select-category-item flex-item"
             :class="{active:defaultValue===item.id}"
             @click="selectItem(item)"
             v-for="item in list" :key="item.id">
          <div class="content text-over-hidden">{{item.name}}</div>
          <el-icon class="right el-icon-arrow-right" v-if="!leaf && defaultValue===item.id"></el-icon>
        </div>
      </template>
      <div class="no-data" v-else>暂无{{title}}</div>
    </div>
  </div>
</template>

<script>
  import {isEmpty} from '../../../assets/js/utils'

  export default {
    name: 'selectCategoryItem',
    props:{
      title:{
        type:String,
        default:'',
      },
      data:{
        type:Array,
        default:function(){
          return []
        },
      },
      value:{
        type:String,
      },
      leaf:{ // 是否为子节点（没有下级、选中时不显示'>'箭头)
        type:Boolean,
        default:false
      }
    },
    data(){
      return {
        list:[],
        defaultValue:'',
        keywords:'',
      }
    },
    created(){
      this.setValue();
      this.setList(this.data);
    },
    methods:{
      setList(list = []){
        if(Array.isArray(list)){
          this.list = list;
          // this.setValue(this.defaultValue);
        }else{
          this.list =[];
        }
      },
      setValue(value = this.value){
        // if(this.list.length){
        //   if(!this.list.some(res=>res.id === value)){
        //     value = '';
        //   }
        // }
        this.defaultValue = value;
      },
      /**
       * 搜索
       */
      search(text = this.keywords){
        let list = [];
        if(isEmpty(text)){
          list = this.data;
        }else{
          list = this.data.filter(res=>{
            return new RegExp(text,'ig').test(res.name)
          })
        }
        this.setList(list);
      },
      selectItem(item){
        if(item.id === this.defaultValue) return false;
        this.defaultValue = item.id;
      },
    },
    watch:{
      data(newVal){
        this.setList(newVal)
      },
      value(newVal){
        this.setValue(newVal);
      },
      defaultValue(newVal){
        this.$emit('input',newVal);
      },
      keywords(newVal){
        this.search(newVal);
      },
    }
  }
</script>

<style scoped lang="less">
  @import "../../../assets/css/data";
  .select-category{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    max-height: 600px;
    display: flex;
    flex-direction: column;
  }
  .select-category-search{
    width: 100%;
    .el-form-item{
      margin: 0;
    }
  }
  .select-category-list{
    width: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: @padding-primary 0;
  }
  .no-data{
    line-height: 40px;
    color: @color-info;
  }
  .select-category-item{
    height: 40px;
    padding: 0 10px;
    cursor: pointer;
    &:hover{
      color: @font-color-heading-text;
      background-color: rgba(0,0,0,0.03);
    }
    &.active{
      color: @color-danger;
    }
  }
</style>
