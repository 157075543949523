<template>
    <el-form
      :ref="editWholeSaleRuleList"
      :model="baseGoodsInfo"
      class="edit-whole-sale-rule">
        <div>
            <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addNewSaleRuleItem()">配置项</el-button>
            <el-tooltip class="item" effect="dark" content="可增加在不同数量区间下对应的价格，默认设置的最低数量为该商品的起批数" placement="right">
                <i class="el-icon-warning" style="margin-left: 20px"></i>
            </el-tooltip>
        </div>
        <div
          :rules="formRules.marketPrice"
          class="whole-saleRule-list-wrap"
          v-for="(item, index) in baseGoodsInfo.wholeSaleRuleList"
          :key="index">
            <span>当批发数量大于等于</span>
            <el-form-item
              style="margin:0px; width:151px"
              :rules="formRules.beginNum"
              :prop="'wholeSaleRuleList['+index+'].beginNum'"
            >
              <el-input-number class="whole-saleRule-num" size="mini" v-model="item.beginNum"></el-input-number>
            </el-form-item>
            <span>时销售价为</span>
            <el-form-item
              style="margin:0px 20px 0 0; width:100px"
              :rules="formRules.price"
              :prop="'wholeSaleRuleList['+index+'].price'">
              <el-input type="number" class="whole-saleRule-num" size="mini" v-model="item.price"></el-input>
            </el-form-item>
            <span>元</span>
            <div class="whole-group-action" v-if="index!=0">
                <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                <span class="action-item whole-item-delete" @click="saleRuleItemDelete(index)"> <el-icon class="el-icon-delete"></el-icon> </span>
                </el-tooltip>
            </div>
        </div>
    </el-form>
</template>
<script>
import * as formRules from '../../../assets/js/formRules'
export default {
    name: 'editWholeSaleRule',
    props:{
      baseGoodsInfo:Object
    },
    data(){
        return {
            formRules:Object.assign({
              beginNum:[
                {trigger: 'blur', required:true, message:'批发数量不能为空',  }
              ],
              price:[
                {trigger: 'blur', required:true, message:'批发价格不能为空',  }
              ]
            },formRules),
            editWholeSaleRuleList:'editWholeSaleRuleList'
        }
    },
    methods:{
        saleRuleItemDelete(i) {
            this.baseGoodsInfo.wholeSaleRuleList.splice(i,1);
        },
        addNewSaleRuleItem() {
            this.baseGoodsInfo.wholeSaleRuleList.push({
                beginNum:'',
                price:''
            })
        }
    }
}
</script>
<style scoped lang="less">
  @import "../../../assets/css/data";
  .whole-saleRule-list-wrap {
    padding: 15px 0px;
    display: flex;
    align-items: center;
  }
  .whole-saleRule-num {
    margin: 0 10px;
  }
  .whole-group-action,
  .whole-item-action{
    display: block;
    border-radius: 6px;
    background-color: #f7f7f7;
    margin-left: @margin-primary;
    .action-item{
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &:not(.disabled) {
        cursor: pointer;
      }
      &.disabled{
        color: #ccc;
      }
    }
    .whole-item-plus{
      color: @color-primary;
      font-size: @font-size-heading;
    }
    .whole-item-delete{
      color: @color-danger;
    }
  }
  .whole-group-action{
    background-color: rgba(red(@color-primary),green(@color-primary),blue(@color-primary),0.1);
    .whole-item-plus{
      font-size: @font-size-main-heading;
    }
  }
</style>
