<template>
  <el-form class="goods-sku-item" :model="sku" :ref="formNameEditSku" v-loading="loading" label-width="0px" inline
    @submit.native.prevent>
    <!-- <div class="goods-sku-header"> -->
    <!-- <el-checkbox class="sku-item-radio" v-model="sku.isSelect" :disabled="isDefaults">
        <div class="goods-sku-name">{{sku.name}}</div>
      </el-checkbox> -->
    <!-- <el-tooltip effect="dark" content="将该项的值批量填充至其他所有项" placement="top-start" v-if="length > 1 && index === 0">
        <div class="batch-fill" @click="batchFill">批量填写</div>
      </el-tooltip> -->
    <!-- </div> -->
    <!-- == -->

    <!-- 采购价 -->
    <div class="goods-sku-row" style="padding: 10px; background: rgba(250, 205, 145, 0.5);">
      <el-form-item label="供货价：" label-width="100px">
        <el-form-item class="form-item form-item-label-row" :rules="formRules.basePurchasePrice" prop="basePurchasePrice">
          <el-tooltip class="item" effect="dark" content="采购价" placement="top-start">
            <el-input :disabled="radio ? false : true" @input="inputValue" v-model="sku.basePurchasePrice"
              class="goods-sku-price-input" placeholder="采购价"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row" :rules="formRules.circulationCost" prop="circulationCost">
          <el-tooltip class="item" effect="dark" content="流通成本=物流包装成本+物流成本" placement="top-start">
            <el-input :disabled="radio ? false : true" @input="inputValue" v-model="sku.circulationCost"
              class="goods-sku-price-input" placeholder="流通成本"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row" :rules="formRules.operationCost" prop="operationCost">
          <el-tooltip class="item" effect="dark" content="运营成本=人力成本+商家利润" placement="top-start">
            <el-input :disabled="radio ? false : true" @input="inputValue" v-model="sku.operationCost"
              class="goods-sku-price-input" placeholder="运营成本"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-save form-item form-item-label-row">
          <el-button :disabled="radio ? false : true" @click="skuCancel">取消</el-button>
          <el-button :disabled="radio ? false : true" @click="saveSku" type="primary">保存</el-button>
        </el-form-item>
      </el-form-item>
    </div>
    <!-- 供货价之和 -->
    <div class="goods-sku-row" style="padding: 10px; background: rgba(129, 211, 248, 0.4);">
      <el-form-item label="供货价之和：" label-width="100px">
        <el-form-item class="form-item form-item-label-row" :rules="formRules.purchasePrice" prop="purchasePrice">
          <el-tooltip class="item" effect="dark" content="供货价之和" placement="top-start">
            <el-input :disabled="true" v-model="sku.purchasePrice" class="goods-sku-price-input" placeholder="供货价之和"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row" :rules="formRules.grossProfitRate" prop="grossProfitRate">
          <el-tooltip class="item" effect="dark"
            :content="`${skuFilter[radio]}增幅比例建议${skuState[this.radio]}%,但保证在10%-${skuState[this.radio]}%之间。`"
            placement="top-start">
            <el-input @blur="grossProfitRateBlur"
              type="number"
              :disabled="radio ? sku.isEdit ? true : false : sku.isEdit ? true : false" v-model="sku.grossProfitRate"
              class="goods-sku-price-input" placeholder="平台增幅比例">
              <!--  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" -->
              <span class="" slot="suffix">%</span>
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row" :rules="formRules.price" prop="price">
          <el-tooltip class="item" effect="dark" content="销售价" placement="top-start">
            <el-input :disabled="true" v-model="sku.price" class="goods-sku-price-input" placeholder="销售价"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row" :rules="formRules.marketPrice" prop="marketPrice">
          <el-tooltip class="item" effect="dark" content="划线价" placement="top-start">
            <el-input :disabled="radio ? sku.isEdit ? true : false : sku.isEdit ? true : false" v-model="sku.marketPrice"
              class="goods-sku-price-input" placeholder="划线价"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>

      </el-form-item>
    </div>
    <!-- 库存 -->
    <div class="goods-sku-row" style="padding: 10px; background: rgba(236, 128, 141, 0.4);">
      <el-form-item label="库存：" label-width="100px">
        <el-form-item class="form-item form-item-label-row" :rules="formRules.stockNum" prop="stockNum">
          <el-tooltip class="item" effect="dark" content="库存" placement="top-start">
            <el-input :disabled="radio ? sku.isEdit ? true : false : sku.isEdit ? true : false" v-model="sku.stockNum"
              class="goods-sku-price-input" placeholder="库存"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item form-item-label-row form-item-cover" :rules="formRules.cover" prop="cover">
          <el-tooltip class="item" effect="dark" content="封面 100x100" placement="top-start">
            <upload-images-one :readOnly="radio ? sku.isEdit ? true : false : sku.isEdit ? true : false"
              :file.sync="sku.cover" width="60px" :files-size="500" files-size-unit="kb"></upload-images-one>
          </el-tooltip>
        </el-form-item>
      </el-form-item>
    </div>
    <!-- == -->
    <!-- <div class="goods-sku-row">
      <el-form-item class="form-item form-item-label-row"
                    :rules="formRules.stockNum"
                    prop="stockNum">
        <el-tooltip class="item" effect="dark" content="库存" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    placeholder="库存"
                    v-model="sku.stockNum">
          </el-input>
        </el-tooltip>
      </el-form-item>


      <el-form-item class="form-item form-item-label-row"
                    :rules="formRules.price"
                    v-if="showCofig !== 2"
                    prop="price">
        <el-tooltip class="item" effect="dark" content="售价" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    placeholder="售价"
                    @change="editPrice"
                    v-model="sku.price">
            <span class="" slot="suffix">元</span>
          </el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item class="form-item form-item-label-row"
                    :rules="formRules.purchasePrice"
                    prop="purchasePrice"
                    v-if="showCofig !== 2">
        <el-tooltip class="item" effect="dark" content="供货价" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    placeholder="供货价"
                    @change="editPurchasePrice"
                    v-model="sku.purchasePrice">
            <span class="" slot="suffix">元</span>
          </el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item class="form-item form-item-label-row"
                    :rules="formRules.costPercent"
                    prop="costPercent"
                    v-if="showPoint && (showCofig == 1)">
        <el-tooltip class="item" effect="dark" content="扣点" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    placeholder="扣点"
                    @change="editCostPercent"
                    v-model="sku.costPercent">
            <span class="" slot="suffix">%</span>
          </el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item class="form-item form-item-label-row"
                    v-if="showPoint && !1">
        <el-tooltip class="item" effect="dark" content="扣点金额" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    disabled
                    placeholder="扣点金额"
                    v-model="sku.cost">
            <span class="" slot="suffix">元</span>
          </el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item class="form-item form-item-label-row"
                    :rules="formRules.marketPrice"
                    prop="marketPrice"
                    v-if="showCofig == 1">
        <el-tooltip class="item" effect="dark" content="市场价" placement="top-start">
          <el-input class="goods-sku-price-input"
                    type="number"
                    placeholder="市场价"
                    v-model="sku.marketPrice">
            <span class="" slot="suffix">元</span>
          </el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item class="form-item form-item-label-row form-item-cover"
                    :rules="formRules.cover"
                    prop="cover">
        <el-tooltip class="item" effect="dark" content="封面 100x100" placement="top-start">
          <upload-images-one :file.sync="sku.cover"
                             width="60px"
                             :files-size="500"
                             files-size-unit="kb"></upload-images-one>
        </el-tooltip>
      </el-form-item>
    </div> -->
    <div>
      <div style="font-size: 12px; line-height: 30px;">基础销售量<label style="color: #999;">(选填)</label></div>
      <el-form-item>
        <el-input v-model="sku.basicSales" :disabled="!basicSalesSwitch" style="width: 90px;">
          <span slot="suffix">件</span>
        </el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { isEmpty, isObject, deepsClone, JSONClone, matchingTwoObject, priceToFixed } from '../../../assets/js/utils'
import { returnBaseDataGoodsDetailsGoodsSkuItem } from '../../../assets/js/modules/goods/goodsDetails'
import { required } from '../../../assets/js/formRules'
import uploadImagesOne from '../../../components/public/upload/uploadImagesOne'
import MD5 from 'crypto-js/md5'
import { mapState } from 'vuex'

function isNumber(num) {
  return typeof num === 'number' && !isNaN(num)
}
function big(num1, num2) {
  num1 = parseFloat(num1)
  num2 = parseFloat(num2)
  if (isNumber(num1) && isNumber(num2)) {
    return num1 > num2
  }
  return false
}
function multiplication(num1, num2, len = 2) {
  return parseFloat((num1 * num2).toFixed(len));
}
function modulo(num1, num2, len = 2) {
  return parseFloat((num1 / num2).toFixed(len));
}
function isSelect(than) {
  // return false;
  return isObject(than) && isObject(than.sku) && than.sku.isSelect;
}
export default {
  name: 'editSkuItem',
  props: {
    value: Object,
    index: Number,
    length: Number,
    showCofig: Number,
    basicSalesSwitch: Number,
    radio: {
      type: String,
      default: ''
    }
  },
  components: {
    uploadImagesOne
  },
  data() {
    let checkPurchasePrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error(`请输入划线价`));
      }

      if (+value < +this.sku.price) {
        return callback(new Error(`划线价不得低于销售价`));
      }
      callback();
    };
    let checkStockNum = (rule, value, callback) => {
      let text = '库存';
      if (!isSelect(this)) {
        return callback();
      }
      if (!value && value !== 0) {
        return callback(new Error(`${text}不能为空`));
      }

      if (!(/(^[0-9]\d*$)/.test(value))) {
        return callback(new Error(`${text}只能为正整数`));
      }

      if ([0, '0'].includes(value)) {
        return callback();
      }
      callback();
    };
    let checkPrice = (rule, value, callback) => {
      let text = '售价';
      if (!isSelect(this)) {
        return callback();
      }
      if (!value && value !== 0) {
        return callback(new Error(`${text}不能为空`));
      }
      if ([0, '0'].includes(value)) {
        return callback();
      }
      if (big(this.sku.purchasePrice, value)) {
        return callback(new Error(`${text}>供货价`));
      }
      callback();
    };

    let checkCostPercent = (rule, value, callback) => {
      let text = '扣点%';
      if (!isSelect(this)) {
        return callback();
      }
      if (!value && value !== 0) {
        return callback(new Error(`${text}不能为空`));
      }
      // if([0,'0'].includes(value)){
      //   return callback();
      // }
      value = priceToFixed(value);
      if (value > 100) {
        return callback(new Error(`${text} > 100%`));
      }
      if (value < this.minPoint) {
        return callback(new Error(`${text} < ${this.minPoint}%`));
      }
      callback();
    };
    let checkMarketPrice = (rule, value, callback) => {
      let text = '市场价';
      if (!isSelect(this)) {
        return callback();
      }
      if ([0, '0', ''].includes(value)) {
        return callback();
      }
      if (big(this.sku.price, value)) {
        return callback(new Error(`${text}>售价`));
      }
      callback();
    };
    let checkCover = (rule, value, callback) => {
      let text = '封面图';
      if (!isSelect(this)) {
        return callback();
      }
      if (!value && value !== 0) {
        return callback(new Error(`${text}不能为空`));
      }
      if ([0, '0'].includes(value)) {
        return callback();
      }
    };
    const grossProfitRates = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入平台增幅比例`));
      }
      if (value < 10) {
        return callback(new Error(`平台增幅比例不得低于10%`));
      }
      if (value > 99) {
        return callback(new Error(`平台增幅比例不得高于99%`));
      }
    };
    return {
      skuFilter: {
        1: "原生态特色农产品",
        2: "传统手工农产品",
        3: "时令产品",
        4: "产地特色电商标品",
      },
      skuState: {
        1: 30,
        2: 25,
        3: 15,
        4: 15,
      },
      loading: false,
      consoleLogName: 'editSkuItem.vue',
      formNameEditSku: 'formNameEditSkuItem',
      formRules: Object.assign({
        basePurchasePrice: [{ required: true, message: '请输入采购价', trigger: 'blur' },],
        circulationCost: [{ required: true, message: '请输入流通成本', trigger: 'blur' },],
        operationCost: [{ required: true, message: '请输入运营成本', trigger: 'blur' }],
        purchasePrice: [{ required: true, message: '请输入供货价之和', trigger: 'blur' }],
        grossProfitRate: [{ validator: grossProfitRates, trigger: 'blur' }],
        price: [{ required: true, message: '请输入销售价', trigger: 'blur' }],
        marketPrice: [{ trigger: 'blur', validator: checkPurchasePrice, }],
        stockNum: [{ required: true, message: '请输入库存', trigger: 'blur' }],
        // stockNum:[{trigger: 'blur',validator:checkStockNum, },],
        // price:[{trigger: 'blur',validator:checkPrice, },],
        // purchasePrice:[
        //   {trigger: 'blur',validator:checkPurchasePrice, },
        // ],
        // costPercent:[
        //   {trigger: 'blur',validator:checkCostPercent,},
        // ],
        // marketPrice:[
        //   {trigger: 'blur',validator:checkMarketPrice,},
        // ],
        cover: [
          { trigger: 'blur', validator: checkCover, },
        ],
      }, { required }),
      sku: returnBaseDataGoodsDetailsGoodsSkuItem(),
    }
  },
  created() {
    this.setSkuData();
    // this.reviseCostAndCostPercent();
  },
  methods: {
    skuCancel() {
      this.sku.isEdit = true
      this.sku.cover = this.sku.cover ? this.sku.cover : undefined
      this.sku.stockNum = this.sku.stockNum ? this.sku.stockNum : undefined
      this.sku.price = undefined
      this.sku.grossProfitRate = undefined
      this.sku.purchasePrice = undefined
      this.sku.operationCost = undefined
      this.sku.circulationCost = undefined
      this.sku.basePurchasePrice = undefined
      this.sku.marketPrice = undefined
    },
    inputValue(val) {
      this.sku.isEdit = true
      this.sku.cover = this.sku.cover ? this.sku.cover : undefined
      this.sku.stockNum = this.sku.stockNum ? this.sku.stockNum : undefined
      this.sku.price = undefined
      this.sku.grossProfitRate = undefined
      this.sku.purchasePrice = undefined
      this.sku.marketPrice = undefined
    },
    saveSku() {
      if (!this.sku.basePurchasePrice) {
        return this.$message.error('请输入采购价')
      }
      if (!this.sku.circulationCost) {
        return this.$message.error('请输入流通成本')
      }
      if (!this.sku.operationCost) {
        return this.$message.error('请输入运营成本')
      }
      let num = parseFloat(this.sku.basePurchasePrice) + parseFloat(this.sku.circulationCost) + parseFloat(this.sku.operationCost)
      this.sku.purchasePrice = num.toFixed(2)
      this.sku.isEdit = false
      // 原生态特色农产品毛利率30%，
      // 传统手工农产品毛利率25%，
      // 时令产品毛利率15%，
      // 产地特色电商标品毛利率15%）
    },
    grossProfitRateBlur() {
      if (!this.sku.grossProfitRate) {
        return this.$message.error('请输入平台增幅比例')
      }
      this.sku.price = parseFloat(this.sku.purchasePrice) * (this.sku.grossProfitRate / 100) + parseFloat(this.sku.purchasePrice)
      this.sku.price = this.sku.price.toFixed(2)
      this.sku.marketPrice = ''
    },
    //
    /** 批量填充 **/
    batchFill() {
      this.$confirm('提示', '其他项已填写的值将被替换，是否批量填充？').then(res => {
        this.$emit('batchFill', this.sku);
      })
    },
    /** 跟新 已选列表*/
    triggerDefaultData() {
      if (this.judgeList()) return false
      console.log(`${this.consoleLogName} this.$emit("input")`)
      this.$emit('input', deepsClone(this.sku));
    },
    judgeList(after = this.value) {
      return matchingTwoObject(this.sku, after, `${this.consoleLogName}`);
    },
    /** 默认赋值*/
    setSkuData(data = this.value) {
      let d = {};
      if (isObject(data)) {
        d = deepsClone(data);
      } else {
        d = returnBaseDataGoodsDetailsGoodsSkuItem()
      }
      console.log(`${this.consoleLogName} 默认赋值`)
      this.sku = d;
      this.reviseCostAndCostPercent();
    },
    /** 售价 改变计算 扣点百分比 或者 供货价
     */
    editPriceInput(e) {
      this.editPrice(this.sku.price);
    },
    editPrice(newVal) {
      if (!newVal) return false;
      let price = parseFloat(newVal)
      if (!isNumber(price)) return false
      let cost = parseFloat(this.sku.cost)
      let costPercent = parseFloat(this.sku.costPercent)
      let purchasePrice = parseFloat(this.sku.purchasePrice)
      if (isNumber(costPercent)) {
        let newPurchasePrice = price - multiplication(price, costPercent / 100);
        let newCost = priceToFixed(price - newPurchasePrice);
        newPurchasePrice = priceToFixed(newPurchasePrice);
        if (purchasePrice !== newPurchasePrice) {
          // this.$set(this.sku,'purchasePrice',newPurchasePrice)
          // this.sku.purchasePrice = newPurchasePrice;
        }
        if (cost !== newCost) {
          this.$set(this.sku, 'cost', newCost)
          // this.sku.cost = newCost;
        }
      } else if (isNumber(purchasePrice)) {
        let newCostPercent = 100 - modulo(purchasePrice * 100, price)
        let newCost = priceToFixed(price - purchasePrice);
        if (costPercent !== newCostPercent) {
          this.$set(this.sku, 'costPercent', newCostPercent)
          // this.sku.costPercent = newCostPercent;
        }
        if (cost !== newCost) {
          this.$set(this.sku, 'cost', newCost)
          // this.sku.cost = newCost;
        }
      }
    },
    /** 供货价 改变计算 扣点百分比
     */
    editPurchasePriceInput(newVal) {
      this.editPurchasePrice(this.sku.purchasePrice);
    },
    editPurchasePrice(newVal) {
      if (!newVal) return false;
      let purchasePrice = parseFloat(newVal)
      if (!isNumber(purchasePrice)) return false
      let price = parseFloat(this.sku.price)
      let cost = parseFloat(this.sku.cost)
      let costPercent = parseFloat(this.sku.costPercent)
      if (isNumber(price)) {
        let newCostPercent = 100 - modulo(purchasePrice * 100, price)
        let newCost = priceToFixed(price - purchasePrice);
        if (costPercent !== newCostPercent) {
          this.$set(this.sku, 'costPercent', newCostPercent)
          // this.sku.costPercent = newCostPercent;
        }
        if (cost !== newCost) {
          this.$set(this.sku, 'cost', newCost)
          // this.sku.cost = newCost;
        }

      }
    },
    /** 扣点百分比 改变计算  供货价
     */
    editCostPercentInput(newVal) {
      this.editCostPercent(this.sku.costPercent);
    },
    editCostPercent(newVal) {
      if (!newVal) return false;
      let costPercent = parseFloat(newVal)
      if (!isNumber(costPercent)) return false
      let price = parseFloat(this.sku.price)
      let purchasePrice = parseFloat(this.sku.purchasePrice)
      let cost = parseFloat(this.sku.cost)
      if (isNumber(price)) {
        let newPurchasePrice = price - multiplication(price, costPercent / 100)
        newPurchasePrice = priceToFixed(newPurchasePrice);
        let newCost = priceToFixed(price - newPurchasePrice);
        if (purchasePrice !== newPurchasePrice) {
          this.$set(this.sku, 'purchasePrice', newPurchasePrice)
          // this.sku.purchasePrice = newPurchasePrice;
        }
        if (cost !== newCost) {
          this.$set(this.sku, 'cost', newCost)
          // this.sku.cost = newCost;
        }
      }
    },
    /**
     * 场景：this.sku.costPercent < vuex.minCostPercent 时,
     * 操作：修正this.sku.costPercent、this.sku.cost
     */
    reviseCostAndCostPercent() {
      if (this.showPoint) return true;
      if (this.sku.costPercent && this.sku.costPercent >= this.minPoint) return true;
      this.sku.costPercent = this.minPoint;
      this.editCostPercentInput(this.minPoint);
    },
    /**
     * 表单提交及重置
     * @param formName
     */
    submitForm(formName = this.formNameEditSku) {
      this.$refs[formName].validate((valid) => {

      });
    },
  },
  computed: {
    isDefaults() {
      return this.sku.name === '默认';
    },
    showPropsName() {
      return this.index === 0;
    },
    ...mapState({
      minPoint: state => state.minCostPercent,
      showPoint: state => state.showCostAndPurchase,
    }),
  },
  watch: {
    radio(newVal) {
      this.sku.isEdit = true
      this.sku.cover = ''
      this.sku.stockNum = undefined
      this.sku.price = undefined
      this.sku.grossProfitRate = undefined
      this.sku.purchasePrice = undefined
      this.sku.operationCost = undefined
      this.sku.circulationCost = undefined
      this.sku.basePurchasePrice = undefined
      this.sku.marketPrice = undefined
    },
    value(newVal) {
      console.log(`${this.consoleLogName} watch value`, newVal)
      if (this.judgeList(newVal)) return false
      this.setSkuData(newVal);
    },
    sku: {//深度监听，可监听到对象、数组的变化
      handler(newVal, oldVal) {
        console.log(`${this.consoleLogName} watch sku`, newVal)
        this.triggerDefaultData(newVal)
        this.$nextTick(() => {
          this.submitForm();
        })
      },
      deep: true
    },
    minPoint(newVal) {
      console.log(`${this.consoleLogName} watch minPoint`, this.sku.name, newVal);
      this.reviseCostAndCostPercent();
    },
    showPoint(newVal) {
      console.log(`${this.consoleLogName} watch showPoint`, this.sku.name, newVal);
      this.reviseCostAndCostPercent();
    },
  }
}
</script>

<style lang="less">
.goods-sku-item {
  .form-item-label-row {

    &,
    .el-form-item__content,
    .goods-sku-price-input {
      width: 140px;
    }
  }
}

.form-save .el-form-item__content {
  width: 150px !important;
}
</style>
<style scoped lang="less">
@import "../../../assets/css/data";

.goods-sku-header {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.goods-sku-name {}

.form-item-cover {
  margin-bottom: 0;
}

.batch-fill {
  margin-left: @margin-primary;
  font-size: 12px;
  cursor: pointer;
  color: @color-danger;

  &:hover {
    text-decoration: underline;
  }
}
</style>
