import { render, staticRenderFns } from "./selectCategoryItem.vue?vue&type=template&id=243c4e09&scoped=true"
import script from "./selectCategoryItem.vue?vue&type=script&lang=js"
export * from "./selectCategoryItem.vue?vue&type=script&lang=js"
import style0 from "./selectCategoryItem.vue?vue&type=style&index=0&id=243c4e09&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243c4e09",
  null
  
)

export default component.exports