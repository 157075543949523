<template>
  <el-form class="edit-sku-wrap"
           :model="editSkuData"
           :ref="formNameEditSku"
           v-loading="loading"
           label-width="0px"
           @submit.native.prevent>
    <el-button class="margin-bottom"
               type="primary"
               icon="el-icon-plus"
               @click="addNewSkuGroup">SKU组</el-button>
    <el-button class="margin-bottom"
               type="danger"
               icon="el-icon-plus"
               @click="setTestDataSkuItemList" v-if="isTest">测试数据</el-button>
    <div class="base-sales">
      <label>基础销量</label>
      <el-switch :active-value="1" :inactive-value="0" v-model="basicSalesSwitchResult" @change="$emit('update:basicSalesSwitch', basicSalesSwitchResult)" />
    </div>

    <template v-for="(group,index) in editSkuData.skuItemList">
      <div class="sku-group">
        <div class="sku-group-row">
          <el-form-item class="form-item form-item-label-row"
                        label=""
                        :rules="formRules.skuGroupName"
                        :prop="'skuItemList['+index+'].skuItemName'">
            <el-input class="sku-group-name"
                      placeholder="填写SKU组名称,比如颜色" title="比如颜色，尺码，规格之类"
                      v-model="group.skuItemName" maxlength="6" show-word-limit></el-input>
          </el-form-item>
          <div class="sku-group-action" v-if="true">
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <span class="action-item sku-item-delete" @click="skuGroupActionDelete(index,group)"> <el-icon class="el-icon-delete"></el-icon> </span>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="上移" placement="top-start">
              <span class="action-item sku-item-prev" @click="index===0?noEvent:skuGroupActionPrev(index,group)" :class="{disabled:index===0}"> <el-icon class="el-icon-arrow-up"></el-icon> </span>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="下移" placement="top-start">
              <span class="action-item sku-item-next " @click="index===editSkuData.skuItemList.length-1?noEvent:skuGroupActionNext(index,group)" :class="{disabled:index===editSkuData.skuItemList.length-1}"> <el-icon class="el-icon-arrow-down"></el-icon> </span>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="添加" placement="top-start">
              <span class="action-item sku-item-plus" @click="addNewSkuItem(index)" > <el-icon class="el-icon-circle-plus-outline"></el-icon> </span>
            </el-tooltip>
          </div>
          <el-button class="sku-item-plus sku-item-plus-button"
                     type="primary"
                     size="mini"
                     plain
                     icon="el-icon-plus"
                     @click="addNewSkuItem(index)"
                     v-else></el-button>
        </div>
        <template v-for="(item,i) in group.skuItemValList">

          <div class="sku-item-wrap">
            <el-checkbox class="sku-item-radio" v-model="item.isSelect"></el-checkbox>
            <el-form-item class="form-item form-item-label-row"
                          label=""
                          :rules="formRules.skuItemName"
                          :prop="'skuItemList['+index+'].skuItemValList['+i+'].skuItemValName'">
              <el-input class="sku-item-name"
                        placeholder="填写SKU值,比如红色，蓝色之类" maxlength="15"  show-word-limit
                        v-model="item.skuItemValName"></el-input>
            </el-form-item>
            <div class="sku-item-action">
              <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                <span class="action-item sku-item-delete" @click="skuItemActionDelete(index,i,item)"> <el-icon class="el-icon-delete"></el-icon> </span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="上移" placement="top-start">
                <span class="action-item sku-item-prev" @click="i===0?noEvent:skuItemActionPrev(index,i,item)" :class="{disabled:i===0}"> <el-icon class="el-icon-arrow-up"></el-icon> </span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="下移" placement="top-start">
                <span class="action-item sku-item-next " @click="i===group.skuItemValList.length-1?noEvent:skuItemActionNext(index,i,item)" :class="{disabled:i===group.skuItemValList.length-1}"> <el-icon class="el-icon-arrow-down"></el-icon> </span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </div>

    </template>

    <div class="el-form-item--label">SKU 与 价格</div>
    <div class="el-form-item--brief">我们将会根据您的自定义SKU组自动为你组合成单项SKU，请选择需要的SKU。如果您只是修改库存，建议您到库存管理里面修改~</div>
    <div >
      <div style="padding: 10px 0 0 10px;font-size: 14px;color: red;">温馨提示：请根据页面顺序填写，否则很容易出错哦！</div>
      <div style="padding: 10px 0 20px;">
        <div style="margin-bottom: 10px;">商品类别：</div>
        <el-radio-group @change="inputRadio" v-model="editSkuData.type">
          <el-radio :label="1">原生态特色农产品</el-radio>
          <el-radio :label="2">传统手工农产品</el-radio>
          <el-radio :label="3">时令产品</el-radio>
          <el-radio :label="4">产地特色电商标品</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(item,index) in editSkuData.goodsSkuList" :key="index">
      <edit-sku-item v-model="editSkuData.goodsSkuList[index]"
                     :key="item.name"
                     :index="index"
                     :length="editSkuData.goodsSkuList.length"
                     :showCofig="showCofig"
                     :radio = editSkuData.type
                     :basicSalesSwitch="basicSalesSwitchResult"
                     @batchFill="batchFill"></edit-sku-item>
    </div>
  </el-form>
  <!--<div class="edit-sku-wrap">-->
    <!--<el-button class=""-->
               <!--type="primary"-->
               <!--icon="el-icon-circle-plus-outline"-->
               <!--@click="addNewSkuGroup">新增SKU组</el-button>-->

    <!--<template v-for="(group,index) in skuItemList">-->
      <!--<div class="sku-group">-->
        <!--<el-input class="sku-group-name"-->
                  <!--placeholder="SKU组名称"-->
                  <!--v-model="group.skuItemName"></el-input>-->
      <!--</div>-->

    <!--</template>-->
  <!--</div>-->
</template>

<script>
  import * as formRules from '../../../assets/js/formRules'
  import {returnBaseDataGoodsDetailsSkuItem,returnBaseDataGoodsDetailsSkuChildrenItem,returnBaseDataGoodsDetailsGoodsSkuItem} from '../../../assets/js/modules/goods/goodsDetails'
  import editSkuItem from './editSkuItem'
  import {matchingTwoObject,JSONClone,deepsClone} from '../../../assets/js/utils'
  import {isTest} from '../../../config'

  export default {
    name: 'editSku',
    components:{
      editSkuItem,
    },
    props:{
      defaultSkuItems:Array,
      defaultGoodsSkuList:Array,
      showCofig: Number,
      basicSalesSwitch: Number,
      type:{
        type:String,
        default:'',
      },
    },
    data(){
      return {
        isTest,
        consoleLogName:'editSku.vue',
        loading:false,
        formRules:Object.assign({
          skuGroupName:[
            {trigger: 'blur',required:true,message:"自定义SKU名称不能为空或前面不能有''_'",
            pattern:  /^(?![_])/,
          },
          ],
          skuItemName:[
            {trigger: 'blur',required:true,message:"自定义SKU名称不能为空或前面不能有''_'",
            pattern:  /^(?![_])/,
           },
          ]
        },formRules),
        formNameEditSku:'formNameEditSku',
        /** 编辑 sku 属性组 */
        editSkuData:{
          /* sku 树形结构 */
          type: '',
          skuItemList:[],
          /* sku 单项列表 */
          goodsSkuList:[],
        },
        basicSalesSwitchResult: 0,
      }
    },
    created(){
      this.editSkuData.type = this.type
      if(this.defaultSkuItems) this.editSkuData.skuItemList = deepsClone(this.defaultSkuItems)||[];
      // if(this.defaultGoodsSkuList) this.editSkuData.goodsSkuList = this.defaultGoodsSkuList
    },
    methods:{
      inputRadio() {
        this.$emit('update:type', this.editSkuData.type);
      },
      /** 批量填充 **/
      batchFill(e){
        let originData = {
          cost: e.cost, // 平台扣点金额 ,
          costPercent: e.costPercent, // 平台扣点百分比 ,
          cover: e.cover,
          grossRate: e.grossRate, // 毛利率 ,
          marketPrice: e.marketPrice, // 市场价 ,
          price: e.price, // 售价
          purchasePrice: e.purchasePrice, // 供货成本价
          salesNum: e.salesNum, // 销量 ,
          stockNum: e.stockNum, // 库存
          basicSales: e.basicSales, // 基础销量
        };
        let list = deepsClone(this.editSkuData.goodsSkuList);
        list.forEach((res,index)=>{
          if(index === 0 ) return false;
          Object.assign(res,originData);
        });
        this.editSkuData.goodsSkuList = list;
      },
      /** 测试数据 **/
      setTestDataSkuItemList(){
        this.editSkuData.skuItemList = [{"skuItemId":"","skuItemName":"颜色","skuItemValList":[{"isSelect":true,"skuItemValId":"","skuItemValName":"红色"},{"isSelect":true,"skuItemValId":"","skuItemValName":"黑色"}]},{"skuItemId":"","skuItemName":"款式","skuItemValList":[{"isSelect":true,"skuItemValId":"","skuItemValName":"M"},{"isSelect":true,"skuItemValId":"","skuItemValName":"L"},{"isSelect":true,"skuItemValId":"","skuItemValName":"XL"}]},{"skuItemId":"","skuItemName":"材质","skuItemValList":[{"isSelect":true,"skuItemValId":"","skuItemValName":"丝绸"},{"isSelect":true,"skuItemValId":"","skuItemValName":"纯棉"}]}];
      },
      /** 跟新 已选列表*/
      triggerSkuItemsList(){
        if(this.judgeSkuItemsList()) return false
        console.log(`${this.consoleLogName} this.$emit("update:defaultSkuItems")`)
        this.$emit('update:defaultSkuItems',deepsClone(this.editSkuData.skuItemList));
      },
      triggerGoodsSkuList(){
        if(this.judgeGoodsSkuList()) return false
        console.log(`${this.consoleLogName} this.$emit("update:defaultGoodsSkuList")`)
        this.$emit('update:defaultGoodsSkuList',deepsClone(this.editSkuData.goodsSkuList));
      },
      judgeSkuItemsList(){
        return matchingTwoObject(this.editSkuData.skuItemList,this.defaultSkuItems,`${this.consoleLogName} judgeSkuItemsList`);
      },
      judgeGoodsSkuList(){
        return matchingTwoObject(this.editSkuData.goodsSkuList,this.defaultGoodsSkuList,`${this.consoleLogName} judgeGoodsSkuList`);
      },
      noEvent(){},
      /** 新增、操作sku 组 */
      addNewSkuGroup(){
        if (this.showCofig == 2) {
          //批发模式
          if(this.defaultSkuItems.length > 1) {
              this.$message({
                message: '批发商品最多设置两个SKU组',
                type: 'warning'
              });
          } else {
            this.editSkuData.skuItemList.push(returnBaseDataGoodsDetailsSkuItem());
          }
        } else {
          this.editSkuData.skuItemList.push(returnBaseDataGoodsDetailsSkuItem());
        }
      },
      skuGroupActionDelete(i,item){
        let list = this.editSkuData.skuItemList;
        list.splice(i,1);
      },
      skuGroupActionPrev(i,item){
        let list = this.editSkuData.skuItemList;
        list.splice(i,1,...list.splice(i-1,1,item));
      },
      skuGroupActionNext(i,item){
        let list = this.editSkuData.skuItemList;
        list.splice(i,1,...list.splice(i+1,1,item));
      },
      /** 新增、操作sku 值 */
      addNewSkuItem(index){
        let list = this.editSkuData.skuItemList[index].skuItemValList;
        this.$set(list,list.length,returnBaseDataGoodsDetailsSkuChildrenItem());
        // this.editSkuData.skuItemList[index].skuItemValList.push(returnBaseDataGoodsDetailsSkuChildrenItem());
      },
      skuItemActionDelete(index,i,item){
        let list = this.editSkuData.skuItemList[index].skuItemValList;
        list.splice(i,1);
      },
      skuItemActionPrev(index,i,item){
        let list = this.editSkuData.skuItemList[index].skuItemValList;
        list.splice(i,1,...list.splice(i-1,1,item));
      },
      skuItemActionNext(index,i,item){
        let list = this.editSkuData.skuItemList[index].skuItemValList;
        list.splice(i,1,...list.splice(i+1,1,item));
      },
      /** 将 树形结构 转译为 单项列表*/
      translationGoodsSkuList(list = this.editSkuData.skuItemList){
        console.log('将 树形结构 转译为 单项列表',list)
        let skuNames =  this.joinSkuNames(list);
        if(skuNames.length){
          let index = skuNames.indexOf('默认');
          if(index >= 0){
            skuNames.splice(index,1)
          }
        }else{
          skuNames.unshift('默认');
        }
        let defaultKeyNames = this.defaultGoodsSkuList.map(res=>res.name);
        let skuList = skuNames.map(name=>{
          if(defaultKeyNames.includes(name)){
            // 合并已匹配值
            let index = defaultKeyNames.indexOf(name);
            return Object.assign({},this.defaultGoodsSkuList[index],{ name,})
          }else{
            return Object.assign({},returnBaseDataGoodsDetailsGoodsSkuItem(),{ name,})
          }
        });
        this.editSkuData.goodsSkuList = skuList;
      },
      joinSkuNames(list = []){
        let skuNames = [];
        list.forEach(res=>{
          if(res.skuItemValList.some(sku=>sku.isSelect)){
            skuNames = this.joinSkuNamesItems(skuNames,res.skuItemValList.filter(sku=>sku.isSelect && sku.skuItemValName));
          }
        })
        return skuNames;
      },
      joinSkuNamesItems(namesArray=[],list=[]){
        let newArray = [];
        if(namesArray.length){
          if(!list.length) return namesArray;
          namesArray.forEach(name=>{
            list.forEach(res=>{
              let itemName = res.skuItemValName;
              if(itemName){
                newArray.push(name + '_' + itemName)
              }else if(!newArray.includes(itemName)){
                newArray.push(name);
              }
            })
          });
        }else{
          newArray = list.map(res=>res.skuItemValName||'').filter(res=>!!res);
        }
        return newArray
      },
    },
    computed:{

    },
    watch:{
      defaultSkuItems(newVal, oldVal){
        console.log(`${this.consoleLogName} watch defaultSkuItems `,newVal)
        if(this.judgeSkuItemsList()) return false
        this.editSkuData.skuItemList = deepsClone(newVal);
        console.log(`${this.consoleLogName} defaultSkuItems 设值 ==========================>`, this.editSkuData.skuItemList);
      },
      defaultGoodsSkuList(newVal, oldVal){
        console.log(`${this.consoleLogName} watch defaultGoodsSkuList `,newVal)
        if(this.judgeGoodsSkuList()) return false
        console.log(`${this.consoleLogName} defaultGoodsSkuList 设值`);
        this.editSkuData.goodsSkuList = deepsClone(newVal);
        console.log(`${this.consoleLogName} defaultGoodsSkuList 设值 ==========================>`, this.editSkuData.goodsSkuList);
        // this.translationGoodsSkuList();
        // this.triggerSkuItemsList();
      },
      'editSkuData.skuItemList':{//深度监听，可监听到对象、数组的变化
        handler(newVal, oldVal){
          console.log(`${this.consoleLogName} watch editSkuData.skuItemList `,newVal)
          this.translationGoodsSkuList(newVal);
          this.triggerSkuItemsList(newVal);
        },
        deep:true
      },
      'editSkuData.goodsSkuList':{//深度监听，可监听到对象、数组的变化
        handler(newVal, oldVal){
          console.log(`${this.consoleLogName} watch editSkuData.goodsSkuList `,newVal)
          this.triggerGoodsSkuList(newVal);
        },
        deep:true
      },
      basicSalesSwitch: {
        handler(n) {
          this.basicSalesSwitchResult = n;
        },
        immediate: true,
        deep: true,
      },
    },
  }
</script>

<style lang="less">
  @import "../../../assets/css/data";
  .sku-item-radio{
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    .el-checkbox__inner{
      border-radius: 50%;
    }
  }
  .sku-item-plus-button{
    &.el-button--mini{
      margin-top: (40px - 28px)/2;
    }
    margin-left: @margin-primary;
  }
</style>

<style scoped lang="less">
  @import "../../../assets/css/data";
  .edit-sku-wrap{

  }
  .sku-group-name{
    width: 300px;
  }
  .sku-group-row,
  .sku-item-wrap{
    display: flex;
    align-items: flex-start;
  }
  .sku-item-name{
    width: 300px - 34px;
  }
  .sku-group-action,
  .sku-item-action{
    display: block;
    border-radius: 6px;
    background-color: #f7f7f7;
    margin-left: @margin-primary;
    .action-item{
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &:not(.disabled) {
        cursor: pointer;
      }
      &.disabled{
        color: #ccc;
      }
    }
    .sku-item-plus{
      color: @color-primary;
      font-size: @font-size-heading;
    }
    .sku-item-delete{
      color: @color-danger;
    }
  }
  .sku-item-action{

  }
  .sku-group-action{
    background-color: rgba(red(@color-primary),green(@color-primary),blue(@color-primary),0.1);
    .sku-item-plus{
      font-size: @font-size-main-heading;
    }
  }
  .margin-bottom {
    margin-bottom: 20px
  }
  .margin-bottom-none-important{
    margin-bottom: 0px !important;
  }
  .base-sales {
    display: inline-block;
    margin: 0 0 0 10px;
    label { margin: 0 10px 0 0; }
  }
</style>
