<template>
  <div class="video-snapshot">
    <el-dialog
      title="视频截图"
      visible
      width="60%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="beforeCloseDrawer"
    >
      <!-- :before-close="beforeCloseDrawer" -->
      <div class="">
        <!-- @tab-click="handleClick" -->
        <el-tabs v-model="activeName">
          <el-tab-pane label="封面截取" name="first">
            <div class="wmax0 flex-center" style="height: 380px;">
              <img style="width: 445px;height: 260px;"  v-if="coverPhoto.length> 10" :src="coverPhoto" />
            </div>

            <div class="flex" style="padding: 0 20px;">
              <el-slider
                class="flex-1"
                v-model="valueNum"
                :max="oVideo.duration"
                @input="changeSlider"
                :step="1"
              />
              <span>{{ oVideo.currentTime }}秒</span>
            </div>
            <el-slider
              style="visibility:hidden"
              class="flex-1"
              v-model="valueNum"
            />
            <div class="flex-jcfe">
              <el-button type="simplicity" @click="beforeCloseDrawer"
                >取消</el-button
              >
              <el-button type="primary" @click="accomplish">完成</el-button>
            </div>
          </el-tab-pane>
          <!-- 本地上传 -->
          <el-tab-pane label="本地上传" name="second">
            <div
              class="wmax0 flex-center"
              style="height: 380px;flex-direction: column;"
            >
              <UploadImagesOne
                ref="UploadImagesOne"
                class="mb10"
                @update:file="img => (coverPhoto = img)"
              ></UploadImagesOne>
              <!-- $emit('update:img', img) -->
              <div class="fs18 mb5">点击上传或将文件拖入此区域</div>
              <div class="">清晰美观的封面有利于推荐，建议 >= 1920*1080</div>
            </div>
            <div class="wmax0 h1 bg-e9e9e9 mb10" />
            <div class="flex-jcfe">
              <el-button type="simplicity" @click="beforeCloseDrawer"
                >取消</el-button
              >
              <el-button type="primary" @click="accomplish">完成</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImagesOne from '@/components/public/upload/uploadImagesOne'

/** 视频截图 */
export default {
  name: 'video-snapshot',
  components: {
    UploadImagesOne
  },
  props: {
    datum: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeName: 'first',
      isShow: !false,
      uploadComplet: false,
      valueNum: 1,
      oVideo: {},
      coverPhoto: '',
      coverPhotoFile: ''
    }
  },
  mounted () {
    console.log('this.datum.videoFile', this.datum)
    this.init(this.datum.videoFile)
  },
  methods: {
    init (file) {
      // const file = this.datum.videoFile
      // console.log(file)
      const blob = new Blob([file]) // 文件转化成二进制文件
      const url = this.datum.video || window.URL.createObjectURL(blob) // 转化成url
      // console.log(blob, url)
      const oVideo = document.createElement('video')
      oVideo.setAttribute('src', url)
      oVideo.crossOrigin = 'anonymous'
      oVideo.setAttribute('controls', 'controls')
      oVideo.currentTime = 1 // 设置当前视频为 第1s
      this.oVideo = oVideo
      setTimeout(() => {
        this.canvas(oVideo)
      }, 500)
    },
    canvas (oVideo) {
      const oCanvas = document.createElement('canvas')
      oCanvas.width = oVideo.videoWidth
      oCanvas.height = oVideo.videoHeight
      oCanvas
        .getContext('2d')
        .drawImage(oVideo, 0, 0, oCanvas.width, oCanvas.height)
      const base64 = oCanvas.toDataURL('image/jpeg')
      oCanvas.toBlob(file => (this.coverPhotoFile = file), 'image/jpeg')
      this.coverPhoto = base64
    },
    beforeCloseDrawer () {
      this.$parent.isVideoSnapshot = false
    },
    changeSlider (e) {
      this.oVideo.currentTime = e || 1
      this.canvas(this.oVideo)
    },
    handleClick () {},
    accomplish () {
      if (this.activeName === 'first') {
        this.$refs.UploadImagesOne.onStart(this.coverPhotoFile)
        return
      }
      this.$emit('update:file', this.coverPhoto)
      this.beforeCloseDrawer()
    },
  },
  watch: {
    coverPhoto (newValue) {
      if (this.activeName === 'first') {
        if (newValue.includes('http://')) {
          this.$emit('update:file', this.coverPhoto)
          this.beforeCloseDrawer()
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.video-snapshot {
  .scroll {
    /*margin:15px;*/
    width: 500px;
    height: 5px;
    background: #ccc;
    position: relative;
  }
  .bar {
    width: 10px;
    height: 20px;
    background: #369;
    position: absolute;
    top: -7px;
    left: 0;
    cursor: pointer;
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    background: #369;
    width: 0;
    height: 10px;
  }
}
</style>
