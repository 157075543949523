<template>
  <div class="selected-attribute-list" v-loading="loading">
    <template v-if="list.length">
      <template v-for="(one,index) in list">
        <el-select class="selected-attribute-item" v-model="selectValueData[one.itemId]" :placeholder="'请选择'+one.name">
          <el-option
            v-for="item in one.attrValList"
            :key="item.valueId"
            :label="item.value"
            :value="item.valueId">
          </el-option>
        </el-select>
      </template>
    </template>
    <div class="color-info" v-else>
      暂无可选“非销售属性”
    </div>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {isEmpty,matchingTwoObject} from '../../../assets/js/utils'

  export default {
    name: 'selectAttributeList',
    props:{
      id:{ // 三级分类id
        type:String,
      },
      defaultList:{ // 默认选中数据结构
        type:Array,
      },
    },
    data(){
      return {
        loading:false,
        list:[],
        selectValueData:{},//选中的值  {一级id:二级id}
        selectList:[], // 根据 selectValueData 拼接的值
      }
    },
    created(){
      this.getList();
    },
    methods:{
      /** 跟新 已选列表*/
      triggerDefaultList(){
        if(this.judgeList()) return false
        this.$emit('update:defaultList',this.selectList);
      },
      judgeList(){
        return matchingTwoObject(this.defaultList,this.selectList,'选择非销售属性');
      },
      /**
       * id 变化时，初始化基础数据*/
      initBaseData(){
        this.list = [];
        this.selectValueData = {};
        this.selectList = [];
      },
      /**
       * 查询列表数据
       */
      getList(id = this.id){
        if(isEmpty(id)) {
          this.classifyAttributesList = []
          return Promise.reject();
        }
        let data = {
          categoryId: id,
        }
        this.loading = true
        return this.axios.get(URL.supplierGoods.getCateAttrItemVals,{
          params:data,
        }).then(res=>{
          this.loading = false;
          this.initSelectValue(res.data);
          this.list = res.data;
        }).catch(res=>{
          this.loading = false;
          this.list = []
        })
      },

      /**
       * 更新数组——初始化属性组默认值 */
      initSelectValue(list = []){
        if(!Array.isArray(list) || list.length<=0){
          this.selectValueData = {};
          return false;
        }
        let selectValueData = {};
        let defaultList = this.defaultList;
        let defaultIds = defaultList.map(res=>res.itemId);
        list.forEach(res=>{
          let value = '';
          if(Array.isArray(res.attrValList)){
            // 给初始化默认值
            // value = res.attrValList[0].valueId;
          }
          if(defaultIds.includes(res.itemId)){
            // 匹配选中
            let index = defaultIds.indexOf(res.itemId);
            let children = defaultList[index].attrValList;
            if(Array.isArray(children) && children.length){
              let item = children[0];
              value = item.valueId;
              selectValueData[res.itemId] = value;
            }
          }
          // selectValueData[res.itemId] = value;
        })
        this.selectValueData = selectValueData;
        return list;
      },
      /**
       * 根据选中的值 拼接处对应的数据结构
       */
      splicingSelectList(selectValueData = this.selectValueData){
        this.selectList = this.list.filter(res=>selectValueData[res.itemId]).map(res=>{
          return {
            name:res.name,
            itemId:res.itemId,
            attrValList:res.attrValList.filter(item=>item.valueId === selectValueData[res.itemId]).map(item=>{
              return{
                value: item.value,
                valueId: item.valueId,
              }
            })
          }
        });
      },
    },
    watch:{
      id(newVal){
        console.log('watch id',newVal)
        this.initBaseData();
        this.getList(newVal);
      },
      defaultList(newVal){
        console.log('watch defaultList',newVal);
        if(this.judgeList()) return false;
        this.splicingSelectList();
      },
      selectValueData:{//深度监听，可监听到对象、数组的变化
        handler(newVal, oldVal){
          console.log('watch selectValueData',newVal);
          this.splicingSelectList();
        },
        deep:true
      },
      selectList(newVal){
        console.log('watch selectList',newVal);
        this.triggerDefaultList();
      },
    }
  }
</script>

<style lang="less">
  @import "../../../assets/css/data";
  .selected-attribute-item{
    margin-right: 20px;
    margin-bottom: 20px;
  }
</style>
