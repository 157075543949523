<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
 <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" ref="ruleForm">
          <div style="width: 100%">
            <el-form-item prop="keyWordType">
              <el-select v-model="searchData.keyWordType" placeholder="请选择" style="width: 150px">
                <el-option v-for="item in searchKeyType"  :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="keyWord">
              <el-input  placeholder="搜索关键字" prefix-icon="el-icon-search" v-model="searchData.keyWord" style="width: 260px;"></el-input>
            </el-form-item>

             <el-form-item>
                 <el-button type="primary" @click="filtrate">筛选</el-button>
                 <el-button type="primary" @click="resetForm('ruleForm')">清空筛选</el-button>
             </el-form-item>

            
          </div>
        </el-form>
      </template>
      
      <template v-slot:table="row">
        <div class="table-list" />
        <el-table :data="tableData">
         <el-table-column label="选择" align="center" width="55">
           <template scope="scope">
              <el-radio :label="scope.$index"  v-model="radio" @change.native="handleSelectionChange(scope.row)">{{""}}</el-radio>
           </template> 
         </el-table-column>
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column width="200" prop="videoCover" label="icon">
               <template slot-scope="scope">
                  <img :src="scope.row.img" class="icon-img" />
              </template>
          </el-table-column>
          <el-table-column label="设备名称" width="300" prop="deviceName">
          </el-table-column>

          <el-table-column width="260" prop="userNickName" label="视频URL">
               <template slot-scope="scope">
                 <div class="facility-info">
                     <div class="facility-info-list"><label>{{scope.row.deviceUrl}}</label></div>
                 </div>
              </template>
          </el-table-column>

        </el-table>
      </template>
      <!-- 分页 -->
     
      <template slot="after">
        <div class="page-footer">
          <div class="operation-btn">
              <el-button type="primary" @click="comfirmBtn">确认</el-button>
              <el-button @click="cancerBtn">取消</el-button>
          </div>
          <div class="paging-row">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </template>
    </table-height-auto>
  
  </div>
</template>

<script>
import {URL} from '../../config'
export default {
  name: 'monitor',   
  data () {
    return {        
        dialogVisible: false,
        radio: '',
        drawer: false,
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        total: 0, // 列表总条量
        pageSize: 5, // 每页显示得条数
        loading: false,
        multipleSelection:null,
        search: {
            status: '1',
            pageNo: '1',
            pageSize: '10',

        },
        searchData: {
            status: '1',
            pageNo: '1',
            pageSize: '10',
            keyWordType: '',
            keyWord: '',
        },
        searchKeyType: [{
            id: '1',
            name:'设备场地/名称'
        }]
    }
  },
   methods: {
     resetForm(formName) {
        this.$refs[formName].resetFields();
      },
     handleSelectionChange(val){
        this.multipleSelection = val;
        console.log('val', val)
     },
       viewMonitorInfo(row){
           this.dialogVisible = true
       },
       addMonitor(){
           this.drawer = true
           this.addMonitor = '新增监控设备'
           this.datainfo = {}
       },
   cancerBtn(){
     this.$emit('closed', false)
   },
   comfirmBtn(){
    let monitorIds = []
     if(this.multipleSelection) {
         monitorIds = [this.multipleSelection.siteMonitorAidsId]
        var monitorImg = this.multipleSelection.img
     }else {
        this.$message({
          message: '请先完成添加关联供应来源!',
          type: 'warning'
        })
        return
     }
   
     var obj = {
       isMonitor:'1',
       monitorImg,
       monitorIds
     }
     this.$emit('intoData',obj)
     this.$emit('closed', false)
   },
     handleSizeChange (val) {
      this.searchData.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.searchData.pageNo = val
      this.getList()
    },
    getList(){
      
      if(this.searchData.keyWordType=='1'){
         this.search.deviceName = this.searchData.keyWord
         delete this.search.seriesNo
      }else {
         this.search.seriesNo = this.searchData.keyWord
         delete this.search.deviceName
      }
         this.axios.get(URL.monitorApi.getMonitorAidsList, {params: this.search}).then(res => {
             let datalist = res.data
             this.tableData = datalist.records
             this.total = parseInt(datalist.total) 
         })
    },
    
    filtrate () {
      this.currentPage = 1
      this.getList()
    },
  },
  mounted(){
      this.getList()
  }
}
</script>


<style lang="less" scoped>
.page-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn{
    color: #07A675 !important;
}
.facility-info{
    display: flex;
    flex-direction: column;
}
.facility-info-list {
    font-size: 12px;
}
.btn-right{
    float: right;
}
.icon-img{
    width: 60px;
    height: 60px;
}
.table-list{
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
}
.filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.enabled{
    color: #07A675;
    margin-right: 5px;
    cursor: pointer;
}
.not-enabled{
    color: #f00;
    margin-right: 5px;
    cursor: pointer;
}
.form-item {
  .el-input {
    max-width: 300px;
  }
}
.goods-cover {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.demo-form-inline {
  display: flex;
  justify-content: space-between;
}

</style>